import { pxToRem, createStyleVariants } from '../../styles'

// Can't use :enabled cause it doesn't work when rendered as a link
const NOT_DISABLED = ':not([disabled])'

export const getSizeStyles = createStyleVariants(() => ({
  xs: {
    width: pxToRem(32),
    height: pxToRem(32),
  },
  sm: {
    width: pxToRem(40),
    height: pxToRem(40),
  },
  md: {
    width: pxToRem(48),
    height: pxToRem(48),
  },
}))

// These are the exact same styles as the `Button` variant styles.
// Maybe we can share them somehow in the future but there isn't any good abstraction yet
// so I'll keep some duplicated styles here for now. -- Hugo (2022-07-03)
export const getVariantStyles = createStyleVariants(({ colors }) => ({
  primary: {
    background: colors.bg.brandPrimary,
    color: colors.text.onBrandPrimary,
    [NOT_DISABLED]: {
      // This prevents hover styles from displaying instead
      // of active styles on touch devices
      '@media(hover: hover)': {
        ':hover': {
          background: colors.bg.brandPrimaryHover,
        },
      },
      ':active': {
        background: colors.bg.brandPrimaryActive,
      },
    },
  },
  secondary: {
    background: colors.bg.brandSecondary,
    color: colors.text.onBrandSecondary,
    [NOT_DISABLED]: {
      '@media(hover: hover)': {
        ':hover': {
          background: colors.bg.brandSecondaryHover,
        },
      },
      ':active': {
        background: colors.bg.brandSecondaryActive,
      },
    },
  },
  tertiary: {
    background: colors.bg.brandTertiary,
    color: colors.text.onBrandTertiary,
    [NOT_DISABLED]: {
      '@media(hover: hover)': {
        ':hover': {
          background: colors.bg.brandTertiaryHover,
        },
      },
      ':active': {
        background: colors.bg.brandTertiaryActive,
      },
    },
  },
  ghost: {
    background: colors.bg.default,
    color: colors.text.default,
    [NOT_DISABLED]: {
      '@media(hover: hover)': {
        ':hover': {
          background: colors.core.gray20,
        },
      },
      ':active': {
        background: colors.core.gray20,
      },
    },
  },
}))
