import styled from '@emotion/styled'
import { forwardRef } from 'react'

import { getFormFieldBaseStyles } from '../../../styles'
import type { HTMLQdsProps } from '../../../types'
import { ChevronDownIcon } from '../../icon'

export interface SelectBaseOptions {
  /**
   * The placeholder for the select. We render an `<option/>` element that has
   * empty value.
   *
   * ```jsx
   * <option value="">{placeholder}</option>
   * ```
   */
  placeholder?: string
  /**
   * If `true`, the select will be invalid
   */
  isInvalid?: boolean
  /**
   * If `true`, the select will be disabled
   */
  isDisabled?: boolean
  /**
   * If `true` the select will be required
   */
  isRequired?: boolean
}

const SelectWrapper = styled.div({
  width: '100%',
  height: 'fit-content',
  position: 'relative',
})
const SelectIconWrapper = styled.div({
  pointerEvents: 'none',
  position: 'absolute',
  height: '100%',
  width: 48,
  right: 0,
  top: 0,
  color: 'currentcolor',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&[data-disabled]': {
    opacity: 0.4,
  },
})

const StyledSelect = styled.select(({ theme }) => ({
  ...getFormFieldBaseStyles(theme),
  height: 48,
  // Ensure the text doesn't end up behind the icon
  paddingRight: 40,
}))

type OmittedProps = 'readOnly' | 'size' | 'multiple' | 'disabled' | 'required'

interface SelectBaseProps extends Omit<HTMLQdsProps<'select'>, OmittedProps>, SelectBaseOptions {}

export const SelectBase = forwardRef<HTMLSelectElement, SelectBaseProps>((props, forwardedRef) => {
  const { placeholder, children, isInvalid, isDisabled, isRequired, ...restProps } = props

  return (
    <SelectWrapper>
      <StyledSelect
        ref={forwardedRef}
        aria-invalid={isInvalid ? true : undefined}
        disabled={isDisabled}
        required={isRequired}
        {...restProps}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {children}
      </StyledSelect>
      <SelectIconWrapper data-disabled={isDisabled ? '' : undefined}>
        <ChevronDownIcon role="presentation" aria-hidden="true" size={16} />
      </SelectIconWrapper>
    </SelectWrapper>
  )
})
