import styled from '@emotion/styled'
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import { forwardRef } from 'react'

import { pxToRem } from '../../styles'

const StyledRadioIndicator = styled(RadioGroupPrimitive.Indicator)(({ theme }) => ({
  background: theme.colors.bg.default,
  border: '2px solid',
  borderColor: theme.colors.border.strong,
  width: pxToRem(20),
  height: pxToRem(20),
  flexShrink: 0,
  borderRadius: theme.radii.full,
  transitionProperty: 'background',
  transitionDuration: '120ms',
  transitionTimingFunction: 'ease-out',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&::after': {
    content: '""',
    display: 'block',
    width: pxToRem(10),
    height: pxToRem(10),
    borderRadius: theme.radii.full,
    background: theme.colors.border.strong,
    transform: 'scale(0.5)',
    opacity: 0,
    transitionProperty: 'transform, opacity',
    transitionDuration: '120ms',
    transitionTimingFunction: 'ease-out',
    transformOrigin: 'center',
  },
  '&[data-state="unchecked"]': {
    '@media(hover: hover)': {
      '&:not(&[data-disabled])[data-hover]::after': {
        transform: 'scale(0.8)', // Resolves to 8px
        opacity: 1,
      },
    },
  },

  '&[data-state="checked"]': {
    background: theme.colors.border.strong,
    '&::after': {
      opacity: 1,
      transform: 'scale(1)',
      background: theme.colors.border.defaultSelected,
    },
  },
}))

type RadioIndicatorProps = Omit<
  RadioGroupPrimitive.RadioGroupIndicatorProps,
  'asChild' | 'forceMount'
>
/**
 * Styled Radix `RadioIndicator` component.
 * @see Docs https://www.radix-ui.com/primitives/docs/components/radio-group#indicator
 * @internal Not to be used outside the library.
 */
export const RadioIndicator = forwardRef<HTMLSpanElement, RadioIndicatorProps>(
  (props, forwardedRef) => <StyledRadioIndicator ref={forwardedRef} forceMount {...props} />,
)
