import {
  AlertCircle,
  AlertTriangle,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  Bell,
  BellOff,
  Bookmark,
  Calendar,
  Camera,
  Check,
  CheckCircle2,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Globe,
  Heart,
  HelpCircle,
  History,
  Home,
  Image,
  Info,
  List,
  ListFilter,
  LogOut,
  Map,
  MapPin,
  Menu,
  MessageCircle,
  Minus,
  MoreHorizontal,
  MoreVertical,
  Pen,
  Plus,
  Search,
  Settings,
  Settings2,
  Share,
  Star,
  Trash2,
  UserRound,
  X,
  XCircle,
} from 'lucide-react'

import { createIcon } from './create-icon'
import { createLucideIcon } from './create-lucide-icon'

export const AlertCircleIcon = createLucideIcon(AlertCircle)
export const AlertTriangleIcon = createLucideIcon(AlertTriangle)
export const ArrowDownIcon = createLucideIcon(ArrowDown)
export const ArrowLeftIcon = createLucideIcon(ArrowLeft)
export const ArrowRightIcon = createLucideIcon(ArrowRight)
export const ArrowUpIcon = createLucideIcon(ArrowUp)
export const BellIcon = createLucideIcon(Bell)
export const BellOffIcon = createLucideIcon(BellOff)
export const BookmarkIcon = createLucideIcon(Bookmark)
export const CalendarIcon = createLucideIcon(Calendar)
export const CameraIcon = createLucideIcon(Camera)
export const CheckCircleIcon = createLucideIcon(CheckCircle2)
export const CheckIcon = createLucideIcon(Check)
export const ChevronDownIcon = createLucideIcon(ChevronDown)
export const ChevronLeftIcon = createLucideIcon(ChevronLeft)
export const ChevronRightIcon = createLucideIcon(ChevronRight)
export const ChevronUpIcon = createLucideIcon(ChevronUp)
export const GlobeIcon = createLucideIcon(Globe)
/* Custom icon based on Lucide's heart icon. */
export const HeartFilledIcon = createIcon({
  viewBox: '0 0 24 24',
  d: 'M2.90381 3.90381C4.12279 2.68482 5.77609 2 7.5 2C8.48018 2 9.37318 2.14018 10.2468 2.52068C10.8597 2.78762 11.4321 3.15937 12 3.63935C12.5679 3.15937 13.1403 2.78762 13.7532 2.52068C14.6268 2.14018 15.5198 2 16.5 2C18.2239 2 19.8772 2.68482 21.0962 3.90381C22.3152 5.12279 23 6.77609 23 8.5C23 11.2418 21.1906 13.2531 19.7035 14.7107L12.7071 21.7071C12.3166 22.0976 11.6834 22.0976 11.2929 21.7071L4.29885 14.7131C2.79442 13.258 1 11.2494 1 8.5C1 6.77609 1.68482 5.12279 2.90381 3.90381Z',
  displayName: 'HeartFilledIcon',
})
export const HeartIcon = createLucideIcon(Heart)
export const HelpCircleIcon = createLucideIcon(HelpCircle)
export const HistoryIcon = createLucideIcon(History)
export const HomeIcon = createLucideIcon(Home)
export const ImageIcon = createLucideIcon(Image)
export const InfoIcon = createLucideIcon(Info)
export const ListIcon = createLucideIcon(List)
export const ListFilterIcon = createLucideIcon(ListFilter)
export const LogOutIcon = createLucideIcon(LogOut)
export const MapIcon = createLucideIcon(Map)
export const MapPinIcon = createLucideIcon(MapPin)
export const MenuIcon = createLucideIcon(Menu)
export const MessageCircleIcon = createLucideIcon(MessageCircle)
export const MinusIcon = createLucideIcon(Minus)
export const MoreHorizontalIcon = createLucideIcon(MoreHorizontal)
export const MoreVerticalIcon = createLucideIcon(MoreVertical)
export const PenIcon = createLucideIcon(Pen)
export const PlusIcon = createLucideIcon(Plus)
export const SearchIcon = createLucideIcon(Search)
export const SettingsIcon = createLucideIcon(Settings)
export const ShareIcon = createLucideIcon(Share)
export const SlidersIcon = createLucideIcon(Settings2)
/* Custom icon based on Lucide's star icon. */
export const StarFilledIcon = createIcon({
  viewBox: '0 0 24 24',
  d: 'M12 1C12.3806 1 12.7282 1.21607 12.8967 1.55738L15.7543 7.34647L22.1446 8.28051C22.5212 8.33555 22.8339 8.59956 22.9512 8.96157C23.0686 9.32358 22.9703 9.72083 22.6977 9.98636L18.0745 14.4894L19.1656 20.851C19.23 21.2261 19.0757 21.6053 18.7677 21.8291C18.4598 22.0528 18.0515 22.0823 17.7145 21.9051L12 18.8998L6.28545 21.9051C5.94853 22.0823 5.54024 22.0528 5.23226 21.8291C4.92429 21.6053 4.77004 21.2261 4.83439 20.851L5.92548 14.4894L1.30227 9.98636C1.02965 9.72083 0.931375 9.32358 1.04875 8.96157C1.16613 8.59956 1.47881 8.33555 1.85537 8.28051L8.24574 7.34647L11.1033 1.55738C11.2718 1.21607 11.6194 1 12 1Z',
  displayName: 'StarFilledIcon',
})

export const StarIcon = createLucideIcon(Star)
export const TrashIcon = createLucideIcon(Trash2)
export const UserIcon = createLucideIcon(UserRound)
export const XCircleIcon = createLucideIcon(XCircle)
export const XIcon = createLucideIcon(X)
