import type { ElementType } from 'react'
import { forwardRef } from 'react'
import * as DropdownPrimitive from '@radix-ui/react-dropdown-menu'
import styled from '@emotion/styled'

import type { IconProps } from '../icon'
import { pxToRem } from '../../styles'

const StyledItem = styled(DropdownPrimitive.Item)(({ theme }) => ({
  ...theme.typography.label.sm,
  color: theme.colors.text.default,
  height: pxToRem(40),
  borderRadius: theme.radii.xs,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing['3x'],
  paddingLeft: theme.spacing['3x'],
  paddingRight: theme.spacing['3x'],
  cursor: 'pointer',
  outline: 'none',
  userSelect: 'none',
  '&[data-highlighted]': {
    background: theme.colors.core.gray10,
  },
  ':not([data-disabled])': {
    ':active': {
      background: theme.colors.core.gray20,
    },
  },
  '&[data-disabled]': {
    opacity: 0.4,
    cursor: 'not-allowed',
  },
}))
const TextContainer = styled.span({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
})

interface DropdownMenuItemOptions {
  /**
   * If `true`, the item will be disabled
   */
  isDisabled?: boolean
  /**
   * Event handler called when the user selects an item (via mouse or keyboard).
   * Calling `event.preventDefault` in this handler will prevent the dropdown from closing when selecting that item.
   */
  onSelect?: (event: Event) => void
  /**
   * Optional text used for typeahead purposes.
   * By default the typeahead behavior will use the `.textContent` of the item.
   * Use this when the content is complex, or you have non-textual content inside.
   */
  textValue?: string
  /**
   * Optional icon to display on the left side of the item content.
   */
  icon?: ElementType<IconProps>
}
export interface DropdownMenuItemProps
  extends Omit<DropdownPrimitive.DropdownMenuItemProps, 'asChild' | keyof DropdownMenuItemOptions>,
    DropdownMenuItemOptions {}

export const DropdownMenuItem = forwardRef<HTMLDivElement, DropdownMenuItemProps>(
  (props, forwardedRef) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { children, disabled, isDisabled, icon: Icon, ...restProps } = props
    return (
      <StyledItem ref={forwardedRef} disabled={disabled || isDisabled} {...restProps}>
        {Icon && <Icon color="default" size={16} aria-hidden />}
        <TextContainer>{children}</TextContainer>
      </StyledItem>
    )
  },
)
