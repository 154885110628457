import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { forwardRef } from 'react'

import type { VariantProps } from '../../styles'
import { createStyleVariants, pxToRem } from '../../styles'
import type { HTMLQdsProps } from '../../types'
import type { ResponsiveProp } from '../../styles/responsive'
import { toMediaQueries } from '../../styles/responsive'

const ANIMATION_DURATION = 1200
const ANIMATION_STAGGER_DELAY = 160

const getSizeStyles = createStyleVariants(() => ({
  sm: { fontSize: pxToRem(8) },
  md: { fontSize: pxToRem(16) },
}))
type LoadingDotsSize = VariantProps<typeof getSizeStyles>

const Wrapper = styled.span<{ size: ResponsiveProp<LoadingDotsSize> }>(({ theme, size }) => ({
  color: theme.colors.core.brown,
  display: 'inline-flex',
  ...toMediaQueries(size, (currentValue) => getSizeStyles(theme)[currentValue]),
  // Odd value but it works better with the animation
  gap: pxToRem(6),
}))
const scaleAnimation = keyframes({
  '0%, 80%, 100%': { transform: 'scale(0)' },
  '40%': { transform: 'scale(1)' },
})
const Dot = styled.span({
  display: 'block',
  width: '1em',
  height: '1em',
  background: 'currentColor',
  borderRadius: 999,

  animationDuration: `${ANIMATION_DURATION}ms`,
  animationTimingFunction: 'ease-in-out',
  animationIterationCount: 'infinite',
  animationFillMode: 'both',
  '&:nth-of-type(2)': { animationDelay: `${ANIMATION_STAGGER_DELAY}ms` },
  '&:nth-of-type(3)': { animationDelay: `${ANIMATION_STAGGER_DELAY * 2}ms` },
  animationName: scaleAnimation,
})

interface LoadingDotsOptions {
  size?: ResponsiveProp<LoadingDotsSize>
}

export interface LoadingDotsProps extends HTMLQdsProps<'span'>, LoadingDotsOptions {}

export const LoadingDots = forwardRef<HTMLSpanElement, LoadingDotsProps>((props, forwardedRef) => {
  const { size = 'md', ...restProps } = props

  return (
    <Wrapper ref={forwardedRef} size={size} {...restProps}>
      <Dot />
      <Dot />
      <Dot />
    </Wrapper>
  )
})
