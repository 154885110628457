import type { ReactNode } from 'react'
import { Fragment, forwardRef } from 'react'
import styled from '@emotion/styled'
import isPropValid from '@emotion/is-prop-valid'

import type { Merge } from '../../types'
import type { Theme } from '../../theme'
import type * as Polymorphic from '../../utils/polymorphic'
import type { ResponsiveProp } from '../../styles/responsive'
import { toMediaQueries } from '../../styles/responsive'

import { getValidChildren } from './stack.utils'
import type { AlignItems, FlexDirection, JustifyContent, FlexWrap } from './stack.types'

type StyledStackProps = Merge<
  StackOptions,
  {
    direction: ResponsiveProp<FlexDirection>
    justifyContent: ResponsiveProp<JustifyContent>
    alignItems: ResponsiveProp<AlignItems>
    gap: ResponsiveProp<GapProp>
  }
>
const StyledStack = styled('div', { shouldForwardProp: isPropValid })<StyledStackProps>(
  ({ theme, wrap, gap }) => ({
    display: 'flex',
    flexWrap: wrap,
    ...toMediaQueries(gap, (currentValue) => ({ gap: theme.spacing[currentValue] })),
  }),
  ({ justifyContent }) => ({
    ...toMediaQueries(justifyContent, (currentValue) => ({ justifyContent: currentValue })),
  }),
  ({ alignItems }) => ({
    ...toMediaQueries(alignItems, (currentValue) => ({ alignItems: currentValue })),
  }),
  // Note that this has to be a separate object to avoid
  // the media queries from `expandResponsiveProp` to be overridden
  // when both direction and gap are responsive
  ({ direction }) => ({
    ...toMediaQueries(direction, (currentValue) => ({ flexDirection: currentValue })),
  }),
)

type GapProp = keyof Theme['spacing']
interface StackOptions {
  /**
   * The direction of the stack.
   * @default 'column'
   */
  direction?: ResponsiveProp<FlexDirection>
  /**
   * The CSS `justify-content` property.
   * Controls the alignment of items on the main axis.
   */
  justifyContent?: ResponsiveProp<JustifyContent>
  /**
   * The CSS `align-items` property.
   * Controls the alignment of items on the cross axis.
   */
  alignItems?: ResponsiveProp<AlignItems>
  /**
   * The CSS `flex-wrap` property.
   * Controls whether children can wrap onto multiple lines.
   * @default 'nowrap'
   */
  wrap?: FlexWrap
  /**
   * The gap between each child element.
   */
  gap?: ResponsiveProp<GapProp>
  /**
   * A divider element to be rendered between each child element.
   *
   * _Note: For the divider to be rendered, the child elements can't be loose strings or numbers.
   *  Wrap them in a `div` or other element._
   */
  divider?: ReactNode
}

type StackComponent = Polymorphic.ForwardRefComponent<'div', StackOptions>
export type StackProps = Polymorphic.PropsOf<StackComponent>

export const Stack = forwardRef((props, forwardedRef) => {
  const {
    as,
    children,
    direction = 'column',
    justifyContent = 'flex-start',
    alignItems = 'stretch',
    divider,
    gap = '0x',
    ...restProps
  } = props

  const hasDivider = Boolean(divider)

  const resolvedChildren = !hasDivider
    ? children
    : getValidChildren(children).map((child, index, validChildren) => {
        // Prefer provided child key and fallback to index
        const key = typeof child.key !== 'undefined' ? child.key : index
        const isLast = index + 1 === validChildren.length

        const currentDivider = isLast ? null : divider

        return (
          <Fragment key={key}>
            {child}
            {currentDivider}
          </Fragment>
        )
      })

  return (
    <StyledStack
      as={as}
      ref={forwardedRef}
      direction={direction}
      justifyContent={justifyContent}
      alignItems={alignItems}
      gap={gap}
      {...restProps}
    >
      {resolvedChildren}
    </StyledStack>
  )
}) as StackComponent
