/**
 * ------ IMPORTANT ------
 * Do not change or add to the contents of this file
 * without consulting with the design team.
 *
 * These are values that make up our brand and even
 * minor changes greatly affects our visual identity.
 *
 * The foundations are losely based on Styled System Theme Specification
 * @see https://styled-system.com/theme-specification/
 * -----------------------
 */

import { breakpoints } from './breakpoints'
import { colors } from './colors'
import { spacing } from './spacing'
import { zIndices } from './z-index'
import { sizes } from './sizes'
import { radii } from './radius'
import { shadows } from './shadows'
import { typography } from './typography'

export const foundations = {
  spacing,
  breakpoints,
  zIndices,
  colors,
  sizes,
  radii,
  shadows,
  typography,
}
