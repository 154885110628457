import styled from '@emotion/styled'
import isPropValid from '@emotion/is-prop-valid'
import { forwardRef } from 'react'

import type { VariantProps } from '../../styles'
import { createStyleVariants } from '../../styles'
import type { Theme } from '../../theme'
import type * as Polymorphic from '../../utils/polymorphic'
import { toMediaQueries, type ResponsiveProp } from '../../styles/responsive'

const getSizeStyles = createStyleVariants(({ typography }) => ({
  xs: typography.body.xs,
  sm: typography.body.sm,
  md: typography.body.md,
  lg: typography.body.lg,
  xl: typography.body.xl,
}))

type ParagraphSize = VariantProps<typeof getSizeStyles>
type ParagraphColor = keyof Theme['colors']['text']

interface StyledParagraphProps {
  size: ResponsiveProp<ParagraphSize>
  color: ParagraphColor
  numberOfLines?: number
  textAlign?: 'left' | 'center' | 'right'
}

const StyledParagraph = styled('p', { shouldForwardProp: isPropValid })<StyledParagraphProps>(
  ({ theme, size, color, numberOfLines, textAlign }) => ({
    margin: 0,
    ...toMediaQueries(size, (currentValue) => getSizeStyles(theme)[currentValue]),
    color: theme.colors.text[color],
    textAlign,
    overflowWrap: 'break-word',
    wordWrap: 'break-word',

    ...(numberOfLines && {
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: numberOfLines,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
  }),
)

interface ParagraphOptions {
  /**
   * Sets the visual size of the text
   * @default 'md'
   */
  size?: ResponsiveProp<ParagraphSize>
  /**
   * Sets the color of the text
   * @default 'normal'
   */
  color?: ParagraphColor
  /**
   * Truncates the text after a specific number of lines
   */
  numberOfLines?: number
  /**
   * Alignment of the paragraph
   * @default 'left'
   */
  textAlign?: 'left' | 'center' | 'right'
}

type ParagraphComponent = Polymorphic.ForwardRefComponent<'p', ParagraphOptions>
export type ParagraphProps = Polymorphic.PropsOf<ParagraphComponent>

export const Paragraph = forwardRef((props, forwardedRef) => {
  const { children, size = 'md', color = 'default', textAlign = 'left', ...rest } = props
  return (
    <StyledParagraph ref={forwardedRef} size={size} color={color} textAlign={textAlign} {...rest}>
      {children}
    </StyledParagraph>
  )
}) as ParagraphComponent
