/**
 * This function is heavily inspired by the `createIcon` function from
 * Chakra UI (https://chakra-ui.com/).
 */

import styled from '@emotion/styled'
import { Children, forwardRef } from 'react'

import type { IconOptions, IconProps } from './icon.types'

const StyledSvg = styled.svg<IconOptions>(({ theme, color = 'default' }) => ({
  color: color === 'currentColor' ? 'currentcolor' : theme.colors.icon[color],
  display: 'inline-block',
  lineHeight: '1em',
}))

export interface CreateIconOptions {
  /**
   * The `svg`'s `viewBox` attribute
   */
  viewBox: string
  /**
   * If the `svg` has a single path, simply copy the path's `d` attribute
   */
  d?: string
  /**
   * The `svg` path or group element
   *
   * @example
   * ```jsx
   *  createIcon({
   *    viewBox: '0 0 512 512',
   *    path: [
   *      <circle cx="50" cy="50" r="50" />,
   *      <path d="M10 10" />,
   *    ],
   *    displayName: 'ExampleIcon',
   * })
   * ```
   */
  path?: React.ReactElement | React.ReactElement[]
  /**
   * The display name of the icon. Useful for debugging
   * and internal use.
   */
  displayName?: string
}

/**
 * Helper function to create an icon component.
 */
export const createIcon = (options: CreateIconOptions) => {
  const { viewBox, d: pathDefinition, displayName = 'UnnamedIcon' } = options
  const path = Children.toArray(options.path)

  const Component = forwardRef<SVGSVGElement, IconProps>((props, forwardedRef) => {
    const { size = 24, ...rest } = props
    return (
      <StyledSvg
        ref={forwardedRef}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        focusable="false"
        viewBox={viewBox}
        fill="currentColor"
        {...rest}
      >
        {path.length ? path : <path fill="currentColor" d={pathDefinition} />}
      </StyledSvg>
    )
  })

  Component.displayName = displayName

  return Component
}
