import { ThemeProvider, CacheProvider, type EmotionCache } from '@emotion/react'
import type { ReactNode } from 'react'
import createCache from '@emotion/cache'

import { GlobalStyles } from './styles'
import type { ThemeOverrides } from './theme'
import { overrideTheme, theme } from './theme'
import { CSSReset } from './styles/css-reset'
import { LocaleProvider } from './i18n/locale-context'
import type { LanguageCode } from './i18n/locales'
import { ToastProvider } from './components/toast/toast-provider'

const fallbackEmotionCache = createCache({ key: 'qds' })
interface QdsProviderProps {
  children: ReactNode
  themeOverrides?: ThemeOverrides
  emotionCache?: EmotionCache
  locale: LanguageCode
}

export function QdsProvider({ children, themeOverrides, emotionCache, locale }: QdsProviderProps) {
  const resolvedTheme = themeOverrides ? overrideTheme(themeOverrides) : theme

  const cache = emotionCache || fallbackEmotionCache

  return (
    <LocaleProvider language={locale || 'en'}>
      <CacheProvider value={cache}>
        <ThemeProvider theme={resolvedTheme}>
          <CSSReset />
          <GlobalStyles />
          <ToastProvider>{children}</ToastProvider>
        </ThemeProvider>
      </CacheProvider>
    </LocaleProvider>
  )
}
