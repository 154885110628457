import styled from '@emotion/styled'
import { forwardRef } from 'react'

import type { HTMLQdsProps } from '../../types'

const StyledDivider = styled.span<Required<DividerOptions>>(({ theme, orientation }) => {
  const borderCssKey = orientation === 'horizontal' ? 'borderTop' : 'borderLeft'
  const sizeCssKey = orientation === 'horizontal' ? 'width' : 'height'
  return {
    display: 'block',
    [sizeCssKey]: '100%',
    flexShrink: 0,
    [borderCssKey]: `1px solid ${theme.colors.border.default}`,
  }
})

interface DividerOptions {
  /**
   * The orientation of the divider.
   *
   * @default 'horizontal'
   */
  orientation?: 'horizontal' | 'vertical'
}

export interface DividerProps extends HTMLQdsProps<'span'>, DividerOptions {}
export const Divider = forwardRef<HTMLDivElement, DividerProps>((props, forwardedRef) => {
  const { orientation = 'horizontal', ...restProps } = props
  return (
    <StyledDivider ref={forwardedRef} orientation={orientation} role="separator" {...restProps} />
  )
})
