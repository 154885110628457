import type { Theme } from '../theme'
import { breakpoints } from '../theme/foundations/breakpoints'
import type { PartialRecord } from '../types'

import type { UseBreakpointOptions } from './use-breakpoint'
import { useBreakpoint } from './use-breakpoint'

type Breakpoints = Theme['breakpoints']
type BreakpointsConfig<T> = PartialRecord<keyof Breakpoints, T> & { base: T }
export type UseBreakpointValueProps<T> = BreakpointsConfig<T>

/**
 * Hook for getting a value based on the current breakpoint.
 *
 * @example
 * const width = useBreakpointValue({ base: '150px', md: '250px' })
 */
export function useBreakpointValue<T>(
  values: UseBreakpointValueProps<T>,
  options?: UseBreakpointOptions,
) {
  const { currentBreakpoint } = useBreakpoint(options)
  let breakpointValue

  if (currentBreakpoint in values) {
    breakpointValue = values[currentBreakpoint]
  } else {
    const breakpointNames = Object.keys(breakpoints) as (keyof Breakpoints)[]
    const currentBreakpointIndex = breakpointNames.indexOf(currentBreakpoint)
    for (let i = currentBreakpointIndex; i >= 0; i--) {
      const key = breakpointNames[i]
      if (key in values) {
        breakpointValue = values[key]
        break
      }
    }
  }
  /* Assert since a value will always be found in loop */
  return breakpointValue as T
}
