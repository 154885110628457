import styled from '@emotion/styled'
import isPropValid from '@emotion/is-prop-valid'
import { forwardRef } from 'react'

import type * as Polymorphic from '../../utils/polymorphic'
import type { Theme } from '../../theme'
import type { ResponsiveProp } from '../../styles/responsive'
import { toMediaQueries } from '../../styles/responsive'

type DisplaySize = keyof Theme['typography']['display']

type DisplayTextAlign = 'left' | 'center' | 'right'
type DisplayTextWrap = 'pretty' | 'balance' | 'wrap' | 'nowrap' | 'stable'

interface StyledDisplayTextProps {
  size: ResponsiveProp<DisplaySize>
  textAlign: DisplayTextAlign
  textWrap: DisplayTextWrap
}

const StyledDisplayText = styled('h2', { shouldForwardProp: isPropValid })<StyledDisplayTextProps>(
  ({ theme, size, textAlign, textWrap }) => ({
    margin: 0,
    ...toMediaQueries(size, (currentValue) => theme.typography.display[currentValue]),
    color: theme.colors.text.default,
    textAlign,
    textWrap,
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
  }),
)

interface DisplayTextOptions {
  /**
   * Sets the visual size of the display text.
   * To override the rendered tag, use the `as` prop.
   *
   * @default 'md'
   */
  size?: ResponsiveProp<DisplaySize>
  /**
   * Sets the text alignment
   * @default 'left'
   */
  textAlign?: DisplayTextAlign
  /**
   * Sets the text wrapping
   * @default 'pretty'
   * Read more on [MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/text-wrap)
   */
  textWrap?: DisplayTextWrap
}

type DisplayTextComponent = Polymorphic.ForwardRefComponent<'h2', DisplayTextOptions>
export type DisplayTextProps = Polymorphic.PropsOf<DisplayTextComponent>

export const DisplayText = forwardRef((props, forwardedRef) => {
  const { as, children, size = 'md', textAlign = 'left', textWrap = 'pretty', ...rest } = props
  return (
    <StyledDisplayText
      as={as}
      ref={forwardedRef}
      size={size}
      textAlign={textAlign}
      textWrap={textWrap}
      {...rest}
    >
      {children}
    </StyledDisplayText>
  )
}) as DisplayTextComponent
