const SUPPORTED_LANGUAGE_CODES = ['en', 'sv', 'fi', 'fr', 'nb'] as const

export type LanguageCode = (typeof SUPPORTED_LANGUAGE_CODES)[number]

export interface Resource {
  close: string
  optional: string
}
const en: Resource = {
  close: 'Close',
  optional: 'Optional',
}

const sv: Resource = {
  close: 'Stäng',
  optional: 'Valfritt',
}

const fi: Resource = {
  close: 'Sulje',
  optional: 'Valinnainen',
}

const fr: Resource = {
  close: 'Fermer',
  optional: 'Facultatif',
}

const nb: Resource = {
  close: 'Steng',
  optional: 'Valgfri',
}

export const locales: Record<LanguageCode, Resource> = {
  en,
  sv,
  fi,
  fr,
  nb,
}
