import { pxToRem } from '../../styles'

export const spacing = {
  /** 0rem (0px) */
  '0x': pxToRem(0),
  /** 0.25rem (4px) */
  '1x': pxToRem(4),
  /** 0.5rem (8px) */
  '2x': pxToRem(8),
  /** 0.75rem (12px) */
  '3x': pxToRem(12),
  /** 1rem (16px) */
  '4x': pxToRem(16),
  /** 1.25rem (20px) */
  '5x': pxToRem(20),
  /** 1.5rem (24px) */
  '6x': pxToRem(24),
  /** 2rem (32px) */
  '8x': pxToRem(32),
  /** 2.5rem (40px) */
  '10x': pxToRem(40),
  /** 3rem (48px) */
  '12x': pxToRem(48),
  /** 3.5rem (56px) */
  '14x': pxToRem(56),
  /** 4rem (64px) */
  '16x': pxToRem(64),
  /** 5rem (80px) */
  '20x': pxToRem(80),
  /** 6rem (96px) */
  '24x': pxToRem(96),
}
