import { forwardRef } from 'react'
import * as DropdownPrimitive from '@radix-ui/react-dropdown-menu'
import styled from '@emotion/styled'

import type { HTMLQdsProps } from '../../types'

const StyledSeparator = styled(DropdownPrimitive.Separator)(({ theme }) => ({
  height: 1,
  background: theme.colors.border.subtle,
  marginTop: theme.spacing['1x'],
  marginBottom: theme.spacing['1x'],
  marginLeft: `-${theme.spacing['2x']}`,
  marginRight: `-${theme.spacing['2x']}`,
}))

export type DropdownMenuDividerProps = HTMLQdsProps<'div'>
export const DropdownMenuDivider = forwardRef<HTMLDivElement, DropdownMenuDividerProps>(
  (props, forwardedRef) => {
    return <StyledSeparator ref={forwardedRef} {...props} />
  },
)
