import type { CSSObject } from '@emotion/react'

import type { Theme } from '../theme'

type NamedStyles = Record<string, CSSObject>
type StyleInterpolation<T> = (theme: Theme) => T
// TODO: Make `createstyleVariants`and `createStyle` accept a regular object instead of a function.
// And infer if the return requires a theme or not.

/**
 * Creates a collection of named style rules.
 */
export function createStyleVariants<T extends NamedStyles>(styles: StyleInterpolation<T>) {
  return (theme: Theme) => styles(theme)
}

/**
 * Create a style object. Useful for creating styles that depend on the theme.
 */
export function createStyle<T extends CSSObject>(styles: StyleInterpolation<T>) {
  return (theme: Theme) => styles(theme)
}

export type VariantProps<T extends ReturnType<typeof createStyle | typeof createStyleVariants>> =
  keyof ReturnType<T>

/**
 * Converts css `px` unit to `rem`.
 * Assumes the root font size is 16px.
 */
export const pxToRem = (px: number) => `${px / 16}rem`
