import { forwardRef } from 'react'
import styled from '@emotion/styled'

import type { HTMLQdsProps } from '../../types'

const Wrapper = styled.aside(({ theme }) => ({
  backgroundColor: theme.colors.bg.inset,
  borderRadius: theme.radii.md,
  paddingInline: theme.spacing['5x'],
  paddingBlock: theme.spacing['4x'],
  ...theme.typography.body.sm,
}))

export type HintBoxTitleProps = HTMLQdsProps<'span'>

const Title = styled.p(({ theme }) => ({
  ...theme.typography.title['3xs'],
  marginBottom: theme.spacing['1x'],
}))

export type HintBoxProps = HTMLQdsProps<'aside'>

const HintRoot = forwardRef<HTMLElement, HintBoxProps>((props, forwardedRef) => {
  const { children, ...rest } = props
  return (
    <Wrapper ref={forwardedRef} {...rest}>
      {children}
    </Wrapper>
  )
})

export const HintBox = Object.assign(HintRoot, { Title })
