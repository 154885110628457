import styled from '@emotion/styled'
import { forwardRef } from 'react'

import { useStableId } from '../../hooks'
import { mergeRefs } from '../../utils/merge-refs'
import type { HTMLQdsProps } from '../../types'
import { dataAttr } from '../../utils/html-attributes'

import { useRadioGroupContext } from './radio-group-context'

const Label = styled.span(({ theme }) => ({
  ...theme.typography.label.md,
  '&[data-disabled]': {
    opacity: 0.4,
  },
}))

export type RadioGroupLabelProps = HTMLQdsProps<'span'>

export const RadioGroupLabel = forwardRef<HTMLSpanElement, RadioGroupLabelProps>(
  (props, forwardedRef) => {
    const { id: idProp, ...restProps } = props
    const id = useStableId(idProp)
    const { labelRefCallback, isDisabled } = useRadioGroupContext({
      consumerName: 'RadioGroupLabel',
    })

    return (
      <Label
        ref={mergeRefs(labelRefCallback, forwardedRef)}
        id={id}
        data-disabled={dataAttr(isDisabled)}
        {...restProps}
      />
    )
  },
)
