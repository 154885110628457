import styled from '@emotion/styled'
import { forwardRef, useRef, useState } from 'react'

import { useFormField, useSafeLayoutEffect } from '../../hooks'
import { useTranslation } from '../../i18n/use-translation'
import { dataAttr } from '../../utils/html-attributes'
import type { HTMLQdsProps } from '../../types'
import { Label } from '../label'
import type { InputBaseOptions } from '../primitives/input-base'
import { InputBase } from '../primitives/input-base'
import { ErrorMessage, FormField, HelperText } from '../_internal'

interface InputContainerProps {
  isPositionRelative: boolean
}
const InputContainer = styled.div<InputContainerProps>(({ isPositionRelative }) => ({
  width: '100%',
  position: isPositionRelative ? 'relative' : undefined,
}))

const OptionalText = styled.span(({ theme }) => ({
  ...theme.typography.body.sm,
  color: theme.colors.text.subtle,
}))

const Suffix = styled.div(({ theme }) => ({
  ...theme.typography.body.md,
  position: 'absolute',
  height: '100%',
  top: 0,
  right: 0,
  display: 'flex',
  alignItems: 'center',
  paddingLeft: theme.spacing['4x'],
  paddingRight: theme.spacing['4x'],
  pointerEvents: 'none',
  '&[data-disabled]': {
    opacity: 0.4,
  },
}))

interface TextFieldOptions extends InputBaseOptions {
  /**
   * The label for the input
   */
  label: string
  /**
   * The error message to display if `isInvalid` is `true`
   */
  errorMessage?: string
  /**
   * If `true`, the input will display an optional indicator.
   * If the `isRequired` prop is also `true`, this prop will be ignored.
   */
  isOptional?: boolean
  /**
   * Text that provides additional guidance to the user
   */
  helperText?: string
  /**
   * Suffixed text to display after the input.
   * This is useful for e.g. displaying units of measurement.
   */
  suffix?: string
}

type OmittedProps = 'children' | 'readOnly' | 'size'

export interface TextFieldProps
  extends Omit<HTMLQdsProps<'input'>, OmittedProps>,
    TextFieldOptions {}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>((props, forwardedRef) => {
  const {
    label,
    isInvalid,
    isDisabled,
    isRequired,
    isOptional: isOptionalProp,
    errorMessage,
    helperText,
    suffix,
    ...restProps
  } = props
  const {
    getLabelProps,
    getFieldProps: getInputProps,
    getErrorMessageProps,
    getHelperTextProps,
  } = useFormField<'input'>(props)

  const [suffixWidth, setSuffixWidth] = useState<number | undefined>(undefined)
  const suffixRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()

  useSafeLayoutEffect(() => {
    setSuffixWidth(suffixRef.current?.offsetWidth)
  }, [suffix])

  const helperTextElement = helperText ? (
    <HelperText {...getHelperTextProps()}>{helperText}</HelperText>
  ) : null

  const shouldShowErrorMessage = isInvalid && errorMessage
  const errorMessageElement = shouldShowErrorMessage ? (
    <ErrorMessage {...getErrorMessageProps()}>{errorMessage}</ErrorMessage>
  ) : null

  const hasSuffix = Boolean(suffix)

  const isOptional = Boolean(!isRequired && isOptionalProp)

  return (
    <FormField>
      <Label {...getLabelProps()}>
        {label}
        {isOptional && <OptionalText>{` (${t('optional')})`}</OptionalText>}
      </Label>

      <InputContainer isPositionRelative={hasSuffix}>
        <InputBase
          ref={forwardedRef}
          {...getInputProps({ ...restProps, style: { paddingRight: suffixWidth } })}
        />
        {hasSuffix && (
          <Suffix ref={suffixRef} aria-hidden="true" data-disabled={dataAttr(isDisabled)}>
            {suffix}
          </Suffix>
        )}
      </InputContainer>
      {errorMessageElement || helperTextElement}
    </FormField>
  )
})
