import styled from '@emotion/styled'
import { forwardRef } from 'react'

import { getFormFieldBaseStyles } from '../../../styles'
import type { HTMLQdsProps } from '../../../types'
import { ariaAttr } from '../../../utils/html-attributes'

const StyledInput = styled('input')(({ theme }) => ({
  ...getFormFieldBaseStyles(theme),
  height: 48,
}))

export interface InputBaseOptions {
  /**
   * If `true`, the input will be invalid
   */
  isInvalid?: boolean
  /**
   * If `true`, the input will be disabled
   */
  isDisabled?: boolean
  /**
   * If `true` the input will be required
   */
  isRequired?: boolean
}

type OmittedProps = 'children' | 'readOnly' | 'size' | 'disabled' | 'required'
export interface InputBaseProps
  extends Omit<HTMLQdsProps<'input'>, OmittedProps>,
    InputBaseOptions {}

export const InputBase = forwardRef<HTMLInputElement, InputBaseProps>((props, forwardedRef) => {
  const { isInvalid, isDisabled, isRequired, ...restProps } = props

  return (
    <StyledInput
      ref={forwardedRef}
      aria-invalid={ariaAttr(isInvalid)}
      disabled={isDisabled}
      required={isRequired}
      {...restProps}
    />
  )
})
