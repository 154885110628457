import styled from '@emotion/styled'
import type { PropsWithChildren } from 'react'

const FieldWrapper = styled.div(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing['2x'],
  width: '100%',
}))

export function FormField({ children }: PropsWithChildren) {
  return <FieldWrapper role="group">{children}</FieldWrapper>
}

export const ErrorMessage = styled.div(({ theme }) => ({
  ...theme.typography.body.sm,
  color: theme.colors.text.negative,
}))

// We use a `<span>` since our radio buttons render as `<button>`
// through Radix. And only phrasing content is allowed inside a `<button>`.
// See: https://validator.nu/#textarea
export const HelperText = styled.span(({ theme }) => ({
  ...theme.typography.body.sm,
  color: theme.colors.text.subtle,
  '&[data-disabled]': {
    opacity: 0.4,
  },
}))
