import styled from '@emotion/styled'
import isPropValid from '@emotion/is-prop-valid'
import { forwardRef } from 'react'
import { UserRound } from 'lucide-react'
import * as AvatarPrimitive from '@radix-ui/react-avatar'

import type { HTMLQdsProps } from '../../types'
import { toMediaQueries, type ResponsiveProp } from '../../styles/responsive'

const SIZE_MAP = {
  xs: 32,
  sm: 40,
  md: 48,
  lg: 64,
  xl: 96,
  '2xl': 128,
}

type AvatarSize = keyof typeof SIZE_MAP

const ICON_SIZE_MAP = {
  xs: 14,
  sm: 16,
  md: 20,
  lg: 32,
  xl: 48,
  '2xl': 64,
}

const TEXT_SIZE_MAP = {
  xs: 12,
  sm: 16,
  md: 20,
  lg: 28,
  xl: 40,
  '2xl': 56,
}

const getInitials = (name: string) => {
  const names = name.trim().split(/\s+/)

  const firstName = names[0]
  const lastName = names.length > 1 ? names[names.length - 1] : ''

  // `charAt(0)` returns empty string if name is empty
  return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase()
}

interface WrapperProps {
  size: ResponsiveProp<AvatarSize>
}
const AvatarRoot = styled(AvatarPrimitive.Root)<WrapperProps>(({ theme, size }) => ({
  ...toMediaQueries(size, (currentValue) => ({
    width: SIZE_MAP[currentValue],
    height: SIZE_MAP[currentValue],
  })),
  flexShrink: 0,
  borderRadius: theme.radii.full,
  background: theme.colors.core.gray20,
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: `0 0 0 1px ${theme.colors.core.blackAlpha5}`,
  color: theme.colors.text.subtle,
}))

const AvatarImage = styled(AvatarPrimitive.Image)({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
})

const AvatarFallback = styled(AvatarPrimitive.Fallback)(({ theme }) => ({
  color: theme.colors.text.subtle,
}))

interface FallbackTextProps {
  avatarSize: ResponsiveProp<AvatarSize>
}
const FallbackText = styled.span<FallbackTextProps>(({ avatarSize }) => ({
  fontWeight: 'bold',
  ...toMediaQueries(avatarSize, (currentValue) => ({
    fontSize: TEXT_SIZE_MAP[currentValue],
  })),
  // To optically align the text in the center
  paddingTop: '0.08em',
  display: 'block',
  // User should never need to select this text
  // so shouldn't cause any accessibility issues
  userSelect: 'none',
}))

const roundToTwoDecimals = (num: number) => Math.round(num * 100) / 100

interface IconProps {
  avatarSize: ResponsiveProp<AvatarSize>
}
const StyledIcon = styled(UserRound, { shouldForwardProp: isPropValid })<IconProps>(
  ({ avatarSize }) => ({
    ...toMediaQueries(avatarSize, (currentValue) => {
      const size = ICON_SIZE_MAP[currentValue]

      return {
        width: size,
        height: size,
        // Always set a minimum stroke width of 2px.
        // If the size is less than 24px, scale the stroke width up
        // to compensate for the scaling down of the icon.
        ...(size < 24 && {
          strokeWidth: roundToTwoDecimals((24 / size) * 2),
        }),
      }
    }),
  }),
)

interface AvatarOptions {
  /**
   * Source url of the image to display. If not passed
   * it will display a fallback icon.
   */
  src?: string
  /**
   * Name of the user. Used to generate the initials
   * if no image is provided, but also for accessibility.
   */
  name?: string
  /**
   * Size of the avatar
   * @default 'md'
   */
  size?: ResponsiveProp<AvatarSize>
}

export interface AvatarProps extends HTMLQdsProps<'span'>, AvatarOptions {}
export const Avatar = forwardRef<HTMLSpanElement, AvatarProps>((props, forwardedRef) => {
  const { src, name, size = 'md', ...restProps } = props
  return (
    <AvatarRoot ref={forwardedRef} size={size} {...restProps}>
      <AvatarImage src={src} alt={name} />
      <AvatarFallback delayMs={src ? 200 : undefined}>
        {name ? (
          <FallbackText avatarSize={size}>{getInitials(name)}</FallbackText>
        ) : (
          <StyledIcon avatarSize={size} role="img" aria-label={name} />
        )}
      </AvatarFallback>
    </AvatarRoot>
  )
})
