import { createStyleVariants } from '../../styles'
import type { VariantProps } from '../../styles'

export const getVariantStyles = createStyleVariants(({ colors }) => ({
  neutral: {
    background: colors.bg.brandSecondary,
    color: colors.core.white,
  },
  error: {
    background: colors.bg.negative,
    color: colors.core.white,
  },
}))
export type ToastVariant = VariantProps<typeof getVariantStyles>
