export const radii = {
  /** 0px */
  none: '0px',
  /** 6px */
  '2xs': '6px',
  /** 8px */
  xs: '8px',
  /** 12px */
  sm: '12px',
  /** 16px */
  md: '16px',
  /** 24px */
  lg: '24px',
  /** 32px */
  xl: '32px',
  /** 48px */
  '2xl': '48px',
  /** 9999px */
  full: '9999px',
}
