import { forwardRef } from 'react'
import styled from '@emotion/styled'

import type { Theme } from '../../theme'
import type { HTMLQdsProps } from '../../types'
import type { ResponsiveProp } from '../../styles/responsive'
import { toMediaQueries } from '../../styles/responsive'

type Spacing = keyof Theme['spacing']

const StyledSpan = styled.span<Required<SpacerOptions>>(({ axis, size, theme }) => ({
  display: 'block',

  ...toMediaQueries(size, (currentValue) => {
    const resolvedValue = theme.spacing[currentValue]
    return {
      width: axis === 'x' ? resolvedValue : 1,
      minWidth: axis === 'x' ? resolvedValue : 1,
      height: axis === 'y' ? resolvedValue : 1,
      minHeight: axis === 'y' ? resolvedValue : 1,
    }
  }),
}))

interface SpacerOptions {
  axis?: 'x' | 'y'
  size: ResponsiveProp<Spacing>
}

export interface SpacerProps extends HTMLQdsProps<'span'>, SpacerOptions {}
export const Spacer = forwardRef<HTMLSpanElement, SpacerProps>((props, forwardedRef) => {
  const { axis = 'y', size, ...restProps } = props

  return <StyledSpan ref={forwardedRef} aria-hidden="true" size={size} axis={axis} {...restProps} />
})
