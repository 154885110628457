import { createStyle } from './css-utils'

export const getFormFieldBaseStyles = createStyle(({ colors, radii, typography, spacing }) => ({
  width: '100%',
  minWidth: 0,
  appearance: 'none',
  paddingLeft: spacing['4x'],
  paddingRight: spacing['4x'],
  border: `1px solid ${colors.border.default}`,
  borderRadius: radii.sm,
  backgroundColor: colors.bg.default,
  color: colors.text.default,
  WebkitTouchCallout: 'none',
  WebkitTapHighlightColor: 'transparent',
  '&::-webkit-date-and-time-value': {
    textAlign: 'left',
  },
  alignItems: 'center',
  ...typography.body.md,

  '&::placeholder': {
    color: colors.text.disabled,
  },
  '&:hover': {
    borderColor: colors.border.defaultHover,
  },
  '&:focus': {
    outline: 0,
    borderColor: colors.border.defaultSelected,
    boxShadow: `0 0 0 1px ${colors.border.defaultSelected}`,
  },
  '&[aria-invalid="true"]': {
    borderColor: colors.border.negative,

    '&:focus': {
      boxShadow: `0 0 0 1px ${colors.border.negative}`,
    },
  },
  '&[disabled], &:disabled, &[data-disabled]': {
    opacity: 0.4,
    borderColor: colors.border.default,
  },

  transitionProperty: 'opacity, border-color, box-shadow',
  transitionDuration: '120ms',
  transitionTimingFunction: 'ease',
}))
