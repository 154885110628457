import styled from '@emotion/styled'
import type { ElementType } from 'react'

import type { Theme } from '../../theme'
import type { IconProps } from '../icon'
import { toMediaQueries, type ResponsiveProp } from '../../styles/responsive'

import type { ButtonSize } from './button-styles'

const roundToTwoDecimals = (num: number) => Math.round(num * 100) / 100

const ICON_SIZE_MAP = {
  xs: 16,
  sm: 16,
  md: 20,
  lg: 20,
  xl: 20,
} as const

const ICON_GAP: Record<ButtonSize, keyof Theme['spacing']> = {
  xs: '2x',
  sm: '3x',
  md: '3x',
  lg: '4x',
  xl: '4x',
}
const ICON_OFFSET: Record<ButtonSize, keyof Theme['spacing']> = {
  xs: '1x',
  sm: '1x',
  md: '1x',
  lg: '2x',
  xl: '2x',
}

interface IconContainerProps {
  buttonSize: ResponsiveProp<ButtonSize>
}

const IconLeftContainer = styled.span<IconContainerProps>(({ theme, buttonSize }) => ({
  flexShrink: 0,
  ...toMediaQueries(buttonSize, (currentValue) => {
    const iconSize = ICON_SIZE_MAP[currentValue]
    return {
      marginLeft: `-${theme.spacing[ICON_OFFSET[currentValue]]}`,
      marginRight: theme.spacing[ICON_GAP[currentValue]],

      '> svg': {
        width: iconSize,
        height: iconSize,
        // Always set a minimum stroke width of 2px.
        // If the size is less than 24px, scale the stroke width up
        // to compensate for the scaling down of the icon.
        ...(iconSize < 24 && {
          strokeWidth: roundToTwoDecimals((24 / iconSize) * 2),
        }),
      },
    }
  }),
}))
const IconRightContainer = styled.span<IconContainerProps>(({ theme, buttonSize }) => ({
  flexShrink: 0,
  ...toMediaQueries(buttonSize, (currentValue) => {
    const iconSize = ICON_SIZE_MAP[currentValue]
    return {
      marginRight: `-${theme.spacing[ICON_OFFSET[currentValue]]}`,
      marginLeft: theme.spacing[ICON_GAP[currentValue]],

      '> svg': {
        width: iconSize,
        height: iconSize,
        // Always set a minimum stroke width of 2px.
        // If the size is less than 24px, scale the stroke width up
        // to compensate for the scaling down of the icon.
        ...(iconSize < 24 && {
          strokeWidth: roundToTwoDecimals((24 / iconSize) * 2),
        }),
      },
    }
  }),
}))

interface ButtonIconProps {
  buttonSize: ResponsiveProp<ButtonSize>
  icon: ElementType<IconProps>
  placement: 'left' | 'right'
}
export function ButtonIcon({ buttonSize, icon: Icon, placement }: ButtonIconProps) {
  const Container = placement === 'left' ? IconLeftContainer : IconRightContainer
  return (
    <Container buttonSize={buttonSize}>
      <Icon aria-hidden="true" color="currentColor" />
    </Container>
  )
}
