import type { MutableRefObject, RefCallback } from 'react'

import type { LegitimateAny } from '../types'

type ReactRef<T> = RefCallback<T> | MutableRefObject<T>
type Maybe<T> = T | null | undefined

function assignRef<T = LegitimateAny>(ref: Maybe<ReactRef<T>>, value: T) {
  // eslint-disable-next-line eqeqeq
  if (ref == null) return

  if (typeof ref === 'function') {
    ref(value)
    return
  }

  ref.current = value
}

export const mergeRefs = <T>(...refs: Maybe<ReactRef<T>>[]) => {
  return (node: T | null) => {
    refs.forEach((ref) => assignRef(ref, node))
  }
}
