import type { CSSObject } from '@emotion/react'

import { theme, type Theme } from '../theme'
import type { PartialRecord } from '../types'

type BreakpointsConfig<T> = PartialRecord<keyof Theme['breakpoints'], T> & { base: T }
export type ResponsiveProp<T> = T | BreakpointsConfig<T>

function isResponsiveProp<T>(value: ResponsiveProp<T>): value is BreakpointsConfig<T> {
  return value && typeof value === 'object' && 'base' in value
}

/**
 * More strictly typed version of Object.entries
 */
const objectEntries = <T extends object>(obj: T) => Object.entries(obj) as [keyof T, T[keyof T]][]

/**
 *
 * @param prop Responsive prop
 * @param styleFn Function that returns a CSSObject based on the value of the prop
 * for a given breakpoint.
 *
 * Example:
 * ```ts
 * toMediaQueries(variant, (currentValue) => ({
 *  background: currentValue === 'primary' ? 'pink' : 'blue',
 * }))
 * ```
 * @returns CSSObject
 */
export const toMediaQueries = <T>(prop: ResponsiveProp<T>, styleFn: (value: T) => CSSObject) => {
  if (!isResponsiveProp(prop)) {
    return styleFn(prop)
  }

  const { base, ...breakpoints } = prop

  /**
   * Due to some very hard to debug issue,
   * we need to use `let` and reassign the value.
   * Else it leads to messed up CSS styles in SSR.
   * I have no clue, but if it works, it works.
   */
  let cssObject = styleFn(base)

  objectEntries(breakpoints).forEach(([breakpoint, value]) => {
    const mediaQuery = theme.mediaQueries[`${breakpoint}Up`]

    if (value) {
      cssObject = {
        ...cssObject,
        [mediaQuery]: styleFn(value),
      }
    }
  })

  return cssObject
}
