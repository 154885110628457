import styled from '@emotion/styled'
import { forwardRef } from 'react'

import { getFormFieldBaseStyles } from '../../../styles'
import type { HTMLQdsProps } from '../../../types'
import { ariaAttr } from '../../../utils/html-attributes'

const StyledTextarea = styled.textarea<{ resize: ResizeProp; minRows: MinRowsProp }>(
  ({ theme, resize, minRows }) => {
    const baseStyles = getFormFieldBaseStyles(theme)
    const paddingVertical = theme.spacing['3x']
    const minHeight = `calc(${baseStyles.lineHeight} * ${minRows} + ${paddingVertical} * 2 + 2px)` // + 2px for border

    return {
      ...baseStyles,
      paddingTop: paddingVertical,
      paddingBottom: paddingVertical,
      scrollPaddingBlockEnd: paddingVertical,
      resize,
      minHeight,
    }
  },
)

type ResizeProp = 'none' | 'both' | 'horizontal' | 'vertical'
type MinRowsProp = number
export interface TextareaBaseOptions {
  /**
   * If `true`, the textarea will be invalid
   */
  isInvalid?: boolean
  /**
   * If `true`, the textarea will be disabled
   */
  isDisabled?: boolean
  /**
   * If `true` the textarea will be required
   */
  isRequired?: boolean
  /**
   * The resize behavior of the textarea
   *
   * @default 'vertical'
   */
  resize?: ResizeProp
  /**
   * The minimum number of rows to display without requiring scrolling or resizing.
   * To disabled resizing use the `resize` prop.
   *
   * @default 3
   */
  minRows?: MinRowsProp
}

type OmittedProps = 'children' | 'readOnly' | 'size' | 'disabled' | 'required' | 'rows' | 'cols'
export interface TextareaBaseProps
  extends Omit<HTMLQdsProps<'textarea'>, OmittedProps>,
    TextareaBaseOptions {}

export const TextareaBase = forwardRef<HTMLTextAreaElement, TextareaBaseProps>(
  (props, forwardedRef) => {
    const {
      isInvalid,
      isDisabled,
      isRequired,
      resize = 'vertical',
      minRows = 3,
      ...restProps
    } = props

    return (
      <StyledTextarea
        ref={forwardedRef}
        aria-invalid={ariaAttr(isInvalid)}
        disabled={isDisabled}
        required={isRequired}
        resize={resize}
        minRows={minRows}
        {...restProps}
      />
    )
  },
)
