import { pxToRem } from '../../styles'

import { spacing } from './spacing'

export const sizes = {
  ...spacing,
  112: pxToRem(112),
  128: pxToRem(128),
  144: pxToRem(144),
  160: pxToRem(160),
  176: pxToRem(176),
  192: pxToRem(192),
  224: pxToRem(224),
  256: pxToRem(256),
  288: pxToRem(288),
  320: pxToRem(320),
  384: pxToRem(384),
  448: pxToRem(448),
  512: pxToRem(512),
  576: pxToRem(576),
  672: pxToRem(672),
  768: pxToRem(768),
  896: pxToRem(896),
  1024: pxToRem(1024),
}
