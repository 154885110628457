import type { ReactElement, ReactNode } from 'react'
import { Children, isValidElement } from 'react'

/**
 * Gets only the valid children of a component,
 * and ignores any nullish or falsy child.
 */
export function getValidChildren(children: ReactNode) {
  return Children.toArray(children).filter((child) => isValidElement(child)) as ReactElement[]
}
