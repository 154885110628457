import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import * as DropdownPrimitive from '@radix-ui/react-dropdown-menu'
import { forwardRef } from 'react'

import type { HTMLQdsProps } from '../../types'

const slideUpAndFadeIn = keyframes({
  '0%': { opacity: 0, transform: 'translateY(4px) scale(0.94)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
})

const slideRightAndFadeIn = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-4px) scale(0.94)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
})

const slideDownAndFadeIn = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-4px) scale(0.94)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
})

const slideLeftAndFadeIn = keyframes({
  '0%': { opacity: 0, transform: 'translateX(4px) scale(0.94)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
})
const slideUpAndFadeOut = keyframes({
  '0%': { opacity: 1, transform: 'translateY(0px)' },
  '100%': { opacity: 0, transform: 'translateY(-4px) scale(0.94)' },
})

const slideRightAndFadeOut = keyframes({
  '0%': { opacity: 1, transform: 'translateX(0px)' },
  '100%': { opacity: 0, transform: 'translateX(4px) scale(0.94)' },
})

const slideDownAndFadeOut = keyframes({
  '0%': { opacity: 1, transform: 'translateY(0)' },
  '100%': { opacity: 0, transform: 'translateY(4px) scale(0.94)' },
})

const slideLeftAndFadeOut = keyframes({
  '0%': { opacity: 1, transform: 'translateX(0)' },
  '100%': { opacity: 0, transform: 'translateX(-4px) scale(0.94)' },
})

const StyledContent = styled(DropdownPrimitive.Content)(({ theme }) => ({
  background: theme.colors.bg.default,
  minWidth: theme.sizes[192],
  maxWidth: theme.sizes[288],
  borderRadius: theme.radii.md,
  boxShadow: theme.shadows.md,
  padding: theme.spacing['2x'],
  '@media (prefers-reduced-motion: no-preference)': {
    transformOrigin: 'var(--radix-dropdown-menu-content-transform-origin)',
    animationDuration: '240ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    willChange: 'transform, opacity',
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideUpAndFadeIn },
      '&[data-side="right"]': { animationName: slideRightAndFadeIn },
      '&[data-side="bottom"]': { animationName: slideDownAndFadeIn },
      '&[data-side="left"]': { animationName: slideLeftAndFadeIn },
    },
    '&[data-state="closed"]': {
      animationDuration: '180ms',
      '&[data-side="top"]': { animationName: slideDownAndFadeOut },
      '&[data-side="right"]': { animationName: slideLeftAndFadeOut },
      '&[data-side="bottom"]': { animationName: slideUpAndFadeOut },
      '&[data-side="left"]': { animationName: slideRightAndFadeOut },
    },
  },
}))

type PrimitiveContentProps = DropdownPrimitive.DropdownMenuContentProps

interface DropdownMenuContentOptions {
  /**
   * Event handler called when focus moves to the trigger after closing.
   * It can be prevented by calling `event.preventDefault`.
   */
  onCloseAutofocus?: PrimitiveContentProps['onCloseAutoFocus']
  /**
   * Event handler called when the escape key is down.
   * It can be prevented by calling `event.preventDefault`.
   */
  onEscapeKeyDown?: PrimitiveContentProps['onEscapeKeyDown']
  /**
   * Event handler called when a pointer event occurs outside the bounds of the component.
   * It can be prevented by calling `event.preventDefault`.
   */
  onPointerDownOutside?: PrimitiveContentProps['onPointerDownOutside']
  /**
   * Event handler called when focus moves outside the bounds of the component.
   * It can be prevented by calling `event.preventDefault`.
   */
  onFocusOutside?: PrimitiveContentProps['onFocusOutside']
  /**
   * Event handler called when an interaction (pointer or focus event) happens outside the bounds of the component.
   * It can be prevented by calling `event.preventDefault`.
   */
  onInteractOutside?: PrimitiveContentProps['onInteractOutside']
  /**
   * The preferred side of the trigger to render against when open.
   * Will be reversed when collisions occur and `avoidCollisions` is enabled.
   *
   * @default "bottom"
   */
  side?: PrimitiveContentProps['side']
  /**
   * The distance in pixels from the trigger.
   *
   * @default 8
   */
  sideOffset?: PrimitiveContentProps['sideOffset']
  /**
   * The preferred alignment against the trigger. May change when collisions occur.
   *
   * @default "center"
   */
  align?: PrimitiveContentProps['align']
  /**
   * The element used as the collision boundary.
   * By default this is the viewport, though you can provide additional element(s) to be included in this check.
   *
   * @default []
   */
  collisionBoundary?: PrimitiveContentProps['collisionBoundary']
  /**
   * Whether to hide the content when the trigger becomes fully occluded.
   *
   * @default false
   */
  hideWhenDetached?: PrimitiveContentProps['hideWhenDetached']
}

export interface DropdownMenuContentProps extends HTMLQdsProps<'div'>, DropdownMenuContentOptions {}

export const DropdownMenuContent = forwardRef<HTMLDivElement, DropdownMenuContentProps>(
  (props, forwardedRef) => {
    const { children, sideOffset = 8, ...restProps } = props

    return (
      <DropdownPrimitive.Portal>
        <StyledContent
          collisionPadding={16}
          ref={forwardedRef}
          sideOffset={sideOffset}
          {...restProps}
        >
          {children}
        </StyledContent>
      </DropdownPrimitive.Portal>
    )
  },
)
