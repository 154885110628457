import styled from '@emotion/styled'
import type { ReactElement } from 'react'
import { forwardRef } from 'react'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { Check } from 'lucide-react'

import { pxToRem } from '../../styles'
import { useStableId } from '../../hooks'
import { ariaAttr, dataAttr } from '../../utils/html-attributes'
import { ErrorMessage, HelperText } from '../_internal'

const Wrapper = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
})

const CheckboxContainer = styled.label(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  alignItems: 'start',
  gap: theme.spacing['3x'],
  position: 'relative',
  cursor: 'pointer',
  WebkitTapHighlightColor: 'transparent',
  WebkitTouchCallout: 'none',
  // Right now not all browsers support the `:has` pseudo-class
  // But we also set the cursor to `not-allowed` in the nested elements
  // so this just makes it so the gap between the checkbox and the text
  // doesn't have the cursor. In the future we should be able to rely
  // on this completely
  '&:has([role="checkbox"][data-disabled])': {
    cursor: 'not-allowed',
  },
}))

const CheckboxRoot = styled(CheckboxPrimitive.Root)(({ theme }) => ({
  position: 'relative',
  WebkitTapHighlightColor: 'transparent',
  WebkitTouchCallout: 'none',
  // We use margin to align the checkbox with the text
  // since `align-items: center` wouldn't work when the text wraps
  marginTop: pxToRem(1),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: pxToRem(20),
  height: pxToRem(20),
  flexShrink: 0,
  border: '2px solid',
  borderColor: theme.colors.border.strong,
  borderRadius: theme.radii['2xs'],
  color: 'transparent',
  transitionProperty: 'background-color, color',
  transitionDuration: '80ms',
  transitionTimingFunction: 'ease',
  '&[data-state="checked"]': {
    backgroundColor: theme.colors.border.strong,
    color: theme.colors.core.brown,
    '&[aria-invalid="true"]': {
      borderColor: theme.colors.border.negative,
    },
  },
  '&[data-state="unchecked"]': {
    backgroundColor: theme.colors.bg.default,
    '@media(hover: hover)': {
      '&:hover': {
        backgroundColor: theme.colors.bg.inset,
        color: theme.colors.core.gray20,
      },
    },
    '&[aria-invalid="true"]': {
      borderColor: theme.colors.border.negative,
    },
  },

  '&[data-disabled], &:disabled, &[disabled]': {
    opacity: 0.4,
    '&[data-state="unchecked"]': {
      backgroundColor: theme.colors.bg.default,
      '&:hover': {
        color: 'transparent',
      },
    },
    '&[data-state="checked"]': {
      backgroundColor: theme.colors.border.strong,
    },
  },
}))

const LabelText = styled.span(({ theme }) => ({
  ...theme.typography.body.md,
  '&[data-disabled], &:disabled': {
    opacity: 0.4,
    cursor: 'not-allowed',
  },
}))

const SupportingTextContainer = styled.div(({ theme }) => ({
  display: 'flex', // Removes line-height from making it too tall
  marginLeft: theme.spacing['8x'],
}))

interface CheckboxOptions {
  /**
   * The label for the checkbox. Accepts a string, or a React component for rendering links within the label.
   *
   * @example
   * ```jsx
   * <Checkbox label="I agree to the terms and conditions" />
   * ```
   *
   * @example
   * ```jsx
   * <Checkbox
   *   label={
   *    <>
   *      I agree to <Link href="/terms">the terms and conditions</Link>
   *    </>
   *  }
   * />
   * ```
   */
  label: string | ReactElement<unknown>
  /**
   * Text that provides additional guidance to the user
   */
  helperText?: string
  /**
   * The checked state of the checkbox when it is initially rendered. Use when you do not need to control its checked state.
   */
  isDefaultChecked?: boolean
  /**
   * The checked state of the checkbox when it is initially rendered. Use when you do not need to control its checked state.
   */
  defaultChecked?: boolean // NOTE: We redeclare this type from Radix because we don't want to support indeterminate state
  /**
   * The controlled checked state of the checkbox. Must be used in conjunction with `onCheckedChange`.
   */
  isChecked?: boolean
  /**
   * The controlled checked state of the checkbox. Must be used in conjunction with `onCheckedChange`.
   */
  checked?: boolean // NOTE: We redeclare this type from Radix because we don't want to support indeterminate state
  /**
   * Event handler called when the checked state of the checkbox changes.
   */
  onCheckedChange?: (checked: boolean) => void
  /**
   * If `true` the checkbox will render in its invalid state.
   * @default false
   */
  isInvalid?: boolean
  /**
   * The error message to display if `isInvalid` is `true`
   */
  errorMessage?: string
  /**
   * If `true` it prevents the user from interacting with the checkbox.
   * @default false
   */
  isDisabled?: boolean
  /**
   * If `true` the user must check the checkbox before the owning form can be submitted.
   * @default false
   */
  isRequired?: boolean
  /**
   * The name of the checkbox. Submitted with its owning form as part of a name/value pair.
   */
  name?: string
  /**
   * The value given as data when submitted with a `name`.
   */
  value?: string
}

export type CheckboxProps = Omit<
  CheckboxPrimitive.CheckboxProps,
  'asChild' | 'children' | 'checked' | 'defaultChecked' | keyof CheckboxOptions
> &
  CheckboxOptions

export const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>((props, forwardedRef) => {
  const {
    label,
    helperText,
    id: idProp,
    defaultChecked: hasHtmlDefaultCheckedAttr,
    isDefaultChecked: isDefaultCheckedProp,
    isChecked: isCheckedProp,
    checked: hasHtmlCheckedAttr,
    isDisabled: isDisabledProp,
    disabled: hasHtmlDisabledAttr,
    isRequired: isRequiredProp,
    required: hasHtmlRequiredAttr,
    isInvalid,
    errorMessage,
    'aria-labelledby': ariaLabelledByProp,
    'aria-describedby': ariaDescribedByProp,
    ...restProps
  } = props
  const id = useStableId(idProp)

  const labelId = `${id}-label`

  const isDefaultChecked = isDefaultCheckedProp ?? hasHtmlDefaultCheckedAttr
  const isChecked = isCheckedProp ?? hasHtmlCheckedAttr
  const isDisabled = isDisabledProp ?? hasHtmlDisabledAttr
  const isRequired = isRequiredProp ?? hasHtmlRequiredAttr

  const ariaLabelledBy = [labelId, ariaLabelledByProp].filter(Boolean).join(' ')

  const hasHelperText = Boolean(helperText)
  const helperTextId = hasHelperText ? `${id}-helper` : undefined

  const helperTextElement = helperText && (
    <SupportingTextContainer>
      <HelperText id={helperTextId} data-disabled={dataAttr(isDisabled)}>
        {helperText}
      </HelperText>
    </SupportingTextContainer>
  )

  const hasError = isInvalid && errorMessage
  const errorMessageId = hasError ? `${id}-error` : undefined

  const errorMessageElement = hasError && (
    <SupportingTextContainer>
      <ErrorMessage id={errorMessageId} role="alert" aria-live="polite">
        {errorMessage}
      </ErrorMessage>
    </SupportingTextContainer>
  )

  const ariaDescribedBy =
    [errorMessageId, !hasError && helperTextId, ariaDescribedByProp].filter(Boolean).join(' ') ||
    undefined

  return (
    <Wrapper>
      <CheckboxContainer>
        <CheckboxRoot
          id={id}
          ref={forwardedRef}
          defaultChecked={isDefaultChecked}
          checked={isChecked}
          disabled={isDisabled}
          required={isRequired}
          aria-invalid={ariaAttr(isInvalid)}
          aria-labelledby={ariaLabelledBy}
          aria-describedby={ariaDescribedBy}
          {...restProps}
        >
          <CheckboxPrimitive.Indicator forceMount>
            <Check
              size={12}
              strokeWidth={3}
              absoluteStrokeWidth
              aria-hidden="true"
              role="presentation"
            />
          </CheckboxPrimitive.Indicator>
        </CheckboxRoot>
        <LabelText
          id={labelId}
          data-disabled={dataAttr(isDisabled)}
          onMouseDown={(ev) => {
            // prevent text selection when double clicking label
            if (ev.detail > 1) {
              ev.preventDefault()
            }
          }}
        >
          {label}
        </LabelText>
      </CheckboxContainer>
      {errorMessageElement || helperTextElement}
    </Wrapper>
  )
})
