import { forwardRef, useCallback, useState } from 'react'
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import styled from '@emotion/styled'

import { ErrorMessage } from '../_internal'
import { useStableId } from '../../hooks'
import { ariaAttr } from '../../utils/html-attributes'

import { RadioGroupProvider } from './radio-group-context'
import { RadioCard } from './radio-card'
import type { RadioCardProps } from './radio-card'
import { RadioGroupLabel } from './radio-group-label'
import type { RadioGroupLabelProps } from './radio-group-label'

const StyledRadioGroupRoot = styled(RadioGroupPrimitive.Root)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing['3x'],
}))

interface RadioGroupOptions {
  /**
   * The value of the radio item that should be checked when initially rendered.
   * Use when you do not need to control the state of the radio items.
   */
  defaultValue?: string
  /**
   * The controlled value of the radio item to check.
   * Should be used in conjunction with `onValueChange`.
   */
  value?: string
  /**
   * Event handler called when the value changes.
   */
  onValueChange?: (value: string) => void
  /**
   * The name of the group. Submitted with its owning form as part of a name/value pair.
   */
  name?: string
  /**
   * If `true` all child radio items will be disabled.
   * @default false
   */
  isDisabled?: boolean
  /**
   * If `true` the user must check a radio item before the owning form can be submitted.
   * @default false
   */
  isRequired?: boolean
  /**
   * If `true` the radio group will be invalid.
   * @default false
   */
  isInvalid?: boolean
  /**
   * The error message to display if `isInvalid` is `true`
   */
  errorMessage?: string
}

export interface RadioGroupProps
  // We omit some props and define them ourselves so that we can have
  // JS doc descriptions for them.
  extends Omit<RadioGroupPrimitive.RadioGroupProps, 'asChild' | keyof RadioGroupOptions>,
    RadioGroupOptions {}

const RadioGroupRoot = forwardRef<HTMLDivElement, RadioGroupProps>((props, forwardedRef) => {
  const {
    children,
    id: idProp,
    disabled: hasHtmlDisabledAttr,
    isDisabled: isDisabledProp,
    required: hasHtmlRequiredAttr,
    isRequired: isRequiredProp,
    'aria-labelledby': ariaLabelledByProp,
    'aria-describedby': ariaDescribedByProp,
    isInvalid,
    errorMessage,
    ...restProps
  } = props

  const isDisabled = isDisabledProp ?? hasHtmlDisabledAttr
  const isRequired = isRequiredProp ?? hasHtmlRequiredAttr

  const id = useStableId(idProp)

  const hasError = isInvalid && errorMessage
  const errorMessageId = hasError ? `${id}-error` : undefined

  const [labelElement, setLabelElement] = useState<HTMLElement | null>(null)

  const labelRefCallback = useCallback((node: HTMLElement | null) => setLabelElement(node), [])
  const labelId = labelElement?.id

  const errorMessageElement = hasError ? (
    <ErrorMessage id={errorMessageId} role="alert" aria-live="polite">
      {errorMessage}
    </ErrorMessage>
  ) : null

  const ariaLabelledBy = [labelId, ariaLabelledByProp].filter(Boolean).join(' ') || undefined
  // Error message should be described first in all scenarios.
  const ariaDescribedBy =
    [errorMessageId, ariaDescribedByProp].filter(Boolean).join(' ') || undefined

  return (
    <RadioGroupProvider
      value={{
        labelRefCallback,
        isDisabled,
        errorMessageId,
      }}
    >
      <StyledRadioGroupRoot
        ref={forwardedRef}
        id={id}
        disabled={isDisabled}
        required={isRequired}
        aria-invalid={ariaAttr(isInvalid)}
        aria-labelledby={ariaLabelledBy}
        aria-describedby={ariaDescribedBy}
        {...restProps}
      >
        {children}
        {errorMessageElement}
      </StyledRadioGroupRoot>
    </RadioGroupProvider>
  )
})

export const RadioGroup = Object.assign(RadioGroupRoot, {
  Card: RadioCard,
  Label: RadioGroupLabel,
})

export type { RadioGroupLabelProps, RadioCardProps }
