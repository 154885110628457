import { forwardRef } from 'react'
import styled from '@emotion/styled'

import { useFormField } from '../../hooks'
import type { HTMLQdsProps } from '../../types'
import { Label } from '../label'
import type { SelectBaseOptions } from '../primitives/select-base'
import { SelectBase } from '../primitives/select-base'
import { ErrorMessage, FormField, HelperText } from '../_internal'
import { useTranslation } from '../../i18n/use-translation'

import type { SelectOptionProps } from './select-option'
import { SelectOption } from './select-option'

const OptionalText = styled.span(({ theme }) => ({
  ...theme.typography.body.sm,
  color: theme.colors.text.subtle,
}))

interface SelectOptions extends SelectBaseOptions {
  /**
   * The label for the select field
   */
  label: string
  /**
   * The error message to display if `isInvalid` is `true`
   */
  errorMessage?: string
  /**
   * Text that provides additional guidance to the user
   */
  helperText?: string
  /**
   * If `true`, the select field will display an optional indicator.
   * If the `isRequired` prop is also `true`, this prop will be ignored.
   */
  isOptional?: boolean
}

type OmittedProps = 'readOnly' | 'size'

export interface SelectProps extends Omit<HTMLQdsProps<'select'>, OmittedProps>, SelectOptions {}

const SelectRoot = forwardRef<HTMLSelectElement, SelectProps>((props, forwardedRef) => {
  const {
    children,
    label,
    isInvalid,
    errorMessage,
    helperText,
    isRequired,
    isOptional: isOptionalProp,
    ...restProps
  } = props
  const {
    getLabelProps,
    getFieldProps: getSelectProps,
    getErrorMessageProps,
    getHelperTextProps,
  } = useFormField(props)
  const { t } = useTranslation()

  const helperTextElement = helperText ? (
    <HelperText {...getHelperTextProps()}>{helperText}</HelperText>
  ) : null

  const shouldShowErrorMessage = isInvalid && errorMessage
  const errorMessageElement = shouldShowErrorMessage ? (
    <ErrorMessage {...getErrorMessageProps()}>{errorMessage}</ErrorMessage>
  ) : null

  const isOptional = Boolean(!isRequired && isOptionalProp)

  return (
    <FormField>
      <Label {...getLabelProps()}>
        {label}
        {isOptional && <OptionalText>{` (${t('optional')})`}</OptionalText>}
      </Label>
      <SelectBase ref={forwardedRef} {...getSelectProps(restProps)}>
        {children}
      </SelectBase>
      {errorMessageElement || helperTextElement}
    </FormField>
  )
})

export const Select = Object.assign(SelectRoot, { Option: SelectOption })
export type { SelectOptionProps }
