import type { ElementType } from 'react'
import { useCallback } from 'react'

import type { HTMLQdsProps, LegitimateAny } from '../types'

import { useStableId } from './use-stable-id'

type FormFieldElement = 'input' | 'select' | 'textarea'

type PropGetter<T extends ElementType = LegitimateAny> = (
  props?: HTMLQdsProps<T>,
) => Record<string, unknown>

interface FormFieldOptions {
  /**
   * The label for the form field
   */
  label: string
  /**
   * The error message to display if `isInvalid` is `true`
   */
  errorMessage?: string
  /**
   * Text that provides additional guidance to the user
   */
  helperText?: string
  /**
   * If `true`, the form field will be invalid
   */
  isInvalid?: boolean
  /**
   * If `true`, the form field will be disabled
   */
  isDisabled?: boolean
  /**
   * If `true` the form field will be required
   */
  isRequired?: boolean
}

type OmittedProps = 'children' | 'readOnly' | 'size'
export type UseFormFieldProps<T extends FormFieldElement> = Omit<HTMLQdsProps<T>, OmittedProps> &
  FormFieldOptions

/**
 * Custom hook that returns props for a form field's label, input, helper text and error message.
 * Meant to be used in conjunction with the `Input`, `Select` or `Textarea` component.
 *
 * Used internally by `TextField`, `Select` and `Textarea`.
 */
export const useFormField = <T extends FormFieldElement>(props: UseFormFieldProps<T>) => {
  const { id: idProp, isDisabled, helperText, errorMessage, isInvalid, isRequired } = props
  const id = useStableId(idProp)

  const errorMessageId = `${id}-error`
  const helperTextId = `${id}-helper`

  const getLabelProps = useCallback<PropGetter<'label'>>(
    (forwardedProps) => ({
      ...forwardedProps,
      htmlFor: id,
      'data-disabled': isDisabled ? '' : undefined,
    }),
    [id, isDisabled],
  )

  const getHelperTextProps = useCallback<PropGetter<'div'>>(
    (forwardedProps) => ({
      ...forwardedProps,
      id: helperTextId,
      'data-disabled': isDisabled ? '' : undefined,
    }),
    [helperTextId, isDisabled],
  )

  const getErrorMessageProps = useCallback<PropGetter<'div'>>(
    (forwardedProps) => ({
      ...forwardedProps,
      id: errorMessageId,
      'aria-live': 'polite',
    }),
    [errorMessageId],
  )

  const getFieldProps = useCallback<PropGetter<T>>(
    (forwardedProps) => {
      const ariaDescribedByIds: string[] = []

      // Error message must be described first in all scenarios.
      if (Boolean(errorMessage) && isInvalid) {
        ariaDescribedByIds.push(errorMessageId)
      } else if (helperText) {
        ariaDescribedByIds.push(helperTextId)
      }

      if (forwardedProps?.['aria-describedby']) {
        ariaDescribedByIds.push(forwardedProps['aria-describedby'])
      }

      return {
        ...forwardedProps,
        'aria-describedby': ariaDescribedByIds.join(' ') || undefined,
        id: forwardedProps?.id ?? id,
        isDisabled,
        isRequired,
        'aria-invalid': isInvalid ? true : undefined,
      }
    },
    [errorMessage, errorMessageId, helperText, helperTextId, id, isDisabled, isInvalid, isRequired],
  )

  return {
    getLabelProps,
    /**
     * Props to be spread on the input, select or textarea element.
     * Function accepts an optional object of props to be merged with the returned props.
     */
    getFieldProps,
    getHelperTextProps,
    getErrorMessageProps,
  }
}
