import type { LegitimateAny, PartialRecord } from '../types'
import { objectKeys } from '../utils/object'

import type { Theme } from './theme'
import { theme } from './theme'

type DeepPartial<T> = { [P in keyof T]?: DeepPartial<T[P]> }

interface TypographyOverride {
  fontFamily?: string
  fontWeight?: '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900'
}

type TypographyOverrides = PartialRecord<keyof Theme['typography'], TypographyOverride>

const overrideTypography = (overrides: TypographyOverrides): Theme['typography'] => {
  const result = Object.assign({}, theme.typography)

  objectKeys(result).forEach((group) => {
    const override = overrides[group]
    if (override) {
      const typographyGroup = result[group]
      objectKeys(typographyGroup).forEach((key) => {
        typographyGroup[key].fontFamily = override.fontFamily || typographyGroup[key].fontFamily
        typographyGroup[key].fontWeight = override.fontWeight || typographyGroup[key].fontWeight
      })
    }
  })

  return result
}

const deepMerge = <T extends Record<string, LegitimateAny>>(
  target: T,
  source: DeepPartial<T>,
): T => {
  const result = Object.assign({}, target)

  objectKeys(source).forEach((key) => {
    if (typeof source[key] === 'object') {
      // @ts-expect-error
      result[key] = deepMerge(result[key], source[key])
    } else {
      // @ts-expect-error
      result[key] = source[key]
    }
  })

  return result
}

export interface ThemeOverrides {
  colors?: DeepPartial<Theme['colors']>
  typography?: TypographyOverrides
}

export const overrideTheme = (overrides: ThemeOverrides) => {
  let typography = theme.typography
  let colors = theme.colors

  if (overrides.typography) {
    typography = overrideTypography(overrides.typography)
  }

  if (overrides.colors) {
    colors = deepMerge(theme.colors, overrides.colors)
  }

  return { ...theme, typography, colors }
}
