import styled from '@emotion/styled'
import * as RadixSwitch from '@radix-ui/react-switch'
import { CheckIcon } from 'lucide-react'
import { forwardRef } from 'react'

import { useStableId } from '../../hooks'
import { pxToRem } from '../../styles'
import { Label } from '../label'

interface SwitchOptions {
  /**
   * The label for the switch
   */
  label: string
  /**
   * The text that appears below the label
   * to provide additional guidance to the user
   */
  helperText?: string
  /**
   * The checked state of the checkbox when it is initially rendered. Use when you do not need to control its checked state.
   */
  isDefaultChecked?: boolean
  /**
   * If `true`, the switch will be checked
   */
  isChecked?: boolean
  /**
   * If `true`, the switch will be disabled and
   * cannot be interacted with
   */
  isDisabled?: boolean
  /**
   * If `true`, indicates that the switch is required
   */
  isRequired?: boolean
  onCheckedChange?: (isChecked: boolean) => void
}

interface SwitchProps
  extends Omit<RadixSwitch.SwitchProps, 'asChild' | keyof SwitchOptions>,
    SwitchOptions {}

export const Switch = forwardRef<HTMLButtonElement, SwitchProps>((props, forwardedRef) => {
  const {
    id: idProp,
    label,
    helperText,
    defaultChecked: hasHtmlDefaultCheckedAttr,
    isDefaultChecked: isDefaultCheckedProp,
    isChecked: isCheckedProp,
    checked: hasHtmlCheckedAttr,
    disabled: hasHtmlDisabledAttr,
    isDisabled: isDisabledProp,
    isRequired: isRequiredProp,
    required: hasHtmlRequiredAttr,
    'aria-labelledby': ariaLabelledByProp,
    'aria-describedby': ariaDescribedByProp,
    onCheckedChange,
    ...restProps
  } = props

  const id = useStableId(idProp)

  const isDefaultChecked = isDefaultCheckedProp || hasHtmlDefaultCheckedAttr
  const isChecked = isCheckedProp || hasHtmlCheckedAttr
  const isDisabled = isDisabledProp || hasHtmlDisabledAttr
  const isRequired = isRequiredProp || hasHtmlRequiredAttr

  const labelId = `${id}-label`
  const ariaLabelledBy = [labelId, ariaLabelledByProp].filter(Boolean).join(' ')

  const hasHelperText = Boolean(helperText)
  const helperTextId = hasHelperText ? `${id}-helper-text` : undefined
  const ariaDescribedBy = [helperTextId, ariaDescribedByProp].filter(Boolean).join(' ') || undefined

  return (
    <SwitchWrapper>
      <SwitchTexts>
        <SwitchLabel id={labelId} htmlFor={id} data-disabled={isDisabled ? '' : undefined}>
          {label}
        </SwitchLabel>
        {hasHelperText && (
          <SwitchHelperText id={helperTextId} data-disabled={isDisabled ? '' : undefined}>
            {helperText}
          </SwitchHelperText>
        )}
      </SwitchTexts>
      <SwitchRoot
        id={id}
        ref={forwardedRef}
        defaultChecked={isDefaultChecked}
        checked={isChecked}
        disabled={isDisabled}
        required={isRequired}
        aria-labelledby={ariaLabelledBy}
        aria-describedby={ariaDescribedBy}
        onCheckedChange={(isChecked) => onCheckedChange?.(isChecked)}
        {...restProps}
      >
        <SwitchThumb>
          <CheckIcon data-part="switch-icon" size={12} absoluteStrokeWidth strokeWidth={2} />
        </SwitchThumb>
      </SwitchRoot>
    </SwitchWrapper>
  )
})

const SwitchLabel = styled(Label)(() => ({
  marginTop: pxToRem(6),
}))

const SwitchHelperText = styled.div(({ theme }) => ({
  flexGrow: 1,
  flexShrink: 1,
  color: theme.colors.text.subtle,
  cursor: 'default',
  ...theme.typography.body.sm,
  '&[data-disabled]': {
    opacity: 0.4,
  },
}))

const SwitchWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing['4x'],
  alignItems: 'start',
}))

const SwitchTexts = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  flexShrink: 1,
  justifyContent: 'space-between',
  gap: theme.sizes['2x'],
}))

const SwitchRoot = styled(RadixSwitch.Root)(({ theme }) => ({
  flexGrow: 0,
  flexShrink: 0,
  flexBasis: 'auto',
  width: pxToRem(56),
  height: pxToRem(32),
  backgroundColor: theme.colors.core.gray40,
  transition: 'background-color 200ms',
  borderRadius: theme.radii.full,
  '&:hover:not([data-disabled])': {
    backgroundColor: theme.colors.core.gray30,
  },
  '&[data-disabled]': {
    opacity: 0.4,
  },
  '&[data-state="checked"]': {
    backgroundColor: theme.colors.bg.brandSecondary,
    '[data-part="switch-icon"]': {
      opacity: 1,
    },
  },
  '&:hover[data-state="checked"]': {
    backgroundColor: theme.colors.bg.brandSecondaryHover,
  },
  '[data-part="switch-icon"]': {
    opacity: 0,
    transition: 'opacity 200ms',
  },
}))

const SwitchThumb = styled(RadixSwitch.Thumb)(({ theme }) => ({
  display: 'flex',
  flexGrow: 0,
  flexShrink: 0,
  flexBasis: 'auto',
  justifyContent: 'center',
  alignItems: 'center',
  width: theme.sizes['6x'],
  height: theme.sizes['6x'],
  boxShadow: theme.shadows.sm,
  backgroundColor: theme.colors.bg.default,
  borderRadius: theme.radii.full,
  transition: 'transform 300ms',
  transform: `translateX(${pxToRem(4)})`,
  willChange: 'transform',
  '&[data-state="checked"]': {
    transform: `translateX(${pxToRem(28)})`,
  },
  '&[data-disabled]': {
    boxShadow: theme.shadows.none,
  },
}))
