import styled from '@emotion/styled'
import isPropValid from '@emotion/is-prop-valid'
import { forwardRef } from 'react'

import type * as Polymorphic from '../../utils/polymorphic'
import type { Theme } from '../../theme'
import type { ResponsiveProp } from '../../styles/responsive'
import { toMediaQueries } from '../../styles/responsive'

type HeadingSize = keyof Theme['typography']['title']
type HeadingColor = keyof Theme['colors']['text']

interface StyledHeadingProps {
  size: ResponsiveProp<HeadingSize>
  color: HeadingColor
  numberOfLines?: number
  textAlign?: 'left' | 'center' | 'right'
}
const StyledHeading = styled('h2', { shouldForwardProp: isPropValid })<StyledHeadingProps>(
  ({ theme, size, color, numberOfLines, textAlign }) => ({
    margin: 0,
    ...toMediaQueries(size, (currentValue) => theme.typography.title[currentValue]),
    color: theme.colors.text[color],
    textAlign,
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    ...(numberOfLines && {
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: numberOfLines,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
  }),
)

interface HeadingOptions {
  /**
   * Sets the visual size of the heading.
   * To override the rendered tag, use the `as` prop.
   *
   * @default 'md'
   */
  size?: ResponsiveProp<HeadingSize>
  /**
   * Sets the color of the heading
   * @default 'default'
   */
  color?: HeadingColor
  /**
   * Truncates the heading after a specific number of lines
   */
  numberOfLines?: number
  /**
   * Alignment of the heading
   * @default 'left'
   */
  textAlign?: 'left' | 'center' | 'right'
}

type HeadingComponent = Polymorphic.ForwardRefComponent<'h2', HeadingOptions>
export type HeadingProps = Polymorphic.PropsOf<HeadingComponent>

export const Heading = forwardRef((props, forwardedRef) => {
  const { as, children, size = 'md', color = 'default', textAlign = 'left', ...rest } = props

  return (
    <StyledHeading
      as={as}
      ref={forwardedRef}
      textAlign={textAlign}
      size={size}
      color={color}
      {...rest}
    >
      {children}
    </StyledHeading>
  )
}) as HeadingComponent
