import { useTheme } from '@emotion/react'
import type { LucideIcon } from 'lucide-react'
import { forwardRef } from 'react'

import type { IconProps } from './icon.types'

export const createLucideIcon = (Icon: LucideIcon) => {
  const Component = forwardRef<SVGSVGElement, IconProps>(
    ({ size = 24, color = 'default', ...props }, ref) => {
      const theme = useTheme()
      const resolvedColor = color === 'currentColor' ? 'currentcolor' : theme.colors.icon[color]

      return (
        <Icon
          ref={ref}
          size={size}
          strokeWidth={2}
          // Always keep a minimum stroke width of 2px
          absoluteStrokeWidth={size < 24}
          color={resolvedColor}
          {...props}
        />
      )
    },
  )

  Component.displayName = Icon.displayName

  return Component
}
