import type { VariantProps } from '../../styles'
import { pxToRem, createStyleVariants } from '../../styles'

// Can't use :enabled cause it doesn't work when rendered as a link
const NOT_DISABLED = ':not([disabled])'

export const getSizeStyles = createStyleVariants(({ typography, spacing }) => ({
  xs: {
    height: pxToRem(32),
    paddingLeft: spacing['4x'],
    paddingRight: spacing['4x'],
    ...typography.button.sm,
  },
  sm: {
    height: pxToRem(40),
    paddingLeft: spacing['5x'],
    paddingRight: spacing['5x'],
    ...typography.button.sm,
  },
  md: {
    height: pxToRem(48),
    paddingLeft: spacing['6x'],
    paddingRight: spacing['6x'],
    ...typography.button.md,
  },
  lg: {
    height: pxToRem(56),
    paddingLeft: spacing['8x'],
    paddingRight: spacing['8x'],
    ...typography.button.md,
  },
  xl: {
    height: pxToRem(64),
    paddingLeft: spacing['8x'],
    paddingRight: spacing['8x'],
    ...typography.button.md,
  },
}))
export type ButtonSize = VariantProps<typeof getSizeStyles>

export const getVariantStyles = createStyleVariants(({ colors }) => ({
  primary: {
    background: colors.bg.brandPrimary,
    color: colors.text.onBrandPrimary,
    [NOT_DISABLED]: {
      '@media(hover: hover)': {
        ':hover': {
          background: colors.bg.brandPrimaryHover,
        },
      },
      ':active': {
        background: colors.bg.brandPrimaryActive,
      },
    },
  },
  secondary: {
    background: colors.bg.brandSecondary,
    color: colors.text.onBrandSecondary,
    [NOT_DISABLED]: {
      '@media(hover: hover)': {
        ':hover': {
          background: colors.bg.brandSecondaryHover,
        },
      },
      ':active': {
        background: colors.bg.brandSecondaryActive,
      },
    },
  },
  tertiary: {
    background: colors.bg.brandTertiary,
    color: colors.text.onBrandTertiary,
    [NOT_DISABLED]: {
      '@media(hover: hover)': {
        ':hover': {
          background: colors.bg.brandTertiaryHover,
        },
      },
      ':active': {
        background: colors.bg.brandTertiaryActive,
      },
    },
  },
}))
export type ButtonVariant = VariantProps<typeof getVariantStyles>
