export const zIndices = {
  /** -1 */
  hide: -1,
  /** auto */
  auto: 'auto',
  /** 0 */
  base: 0,
  /** 10 */
  docked: 10,
  /** 1000 */
  dropdown: 1000,
  /** 1100 */
  sticky: 1100,
  /** 1200 */
  banner: 1200,
  /** 1300 */
  overlay: 1300,
  /** 1400 */
  modal: 1400,
  /** 1500 */
  popover: 1500,
  /** 1600 */
  skipLink: 1600,
  /** 1700 */
  toast: 1700,
  /** 1800 */
  tooltip: 1800,
}
