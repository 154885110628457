const core = {
  black: '#000000',
  white: '#ffffff',

  gray90: '#26261D',
  gray80: '#424236',
  gray70: '#545448',
  gray60: '#78786C',
  gray50: '#A3A396',
  gray40: '#D6D6CD',
  gray30: '#E5E5DF',
  gray20: '#F0F0EB',
  gray10: '#F9F9F6',
  pink: '#FF84B6',
  uiPink: '#FF99C2',
  uiPinkDark: '#FF84B6',
  uiPinkLight: '#FFA3C8',

  brown: '#342620',
  brownDark: '#2A1E1A',
  brownLight: '#3C2F29',

  offWhite: '#F0F0EB',
  offWhiteDark: '#E0E0D6',
  offWhiteLight: '#F6F6F3',

  softPink: '#FFE8F0',

  warmYellow: '#FFD66C',

  softYellow: '#FFF8CC',

  red90: '#8A1100',
  red80: '#A81400',
  red70: '#C21700',
  red60: '#DE1B00',
  red50: '#EF452E',
  red40: '#F47E6E',
  red30: '#F8B2A8',
  red20: '#FAD8D4',
  red10: '#FEF3F1',

  green90: '#00661D',
  green80: '#007D23',
  green70: '#009029',
  green60: '#00A62F',
  green50: '#30B757',
  green40: '#66CA82',
  green30: '#A1DEB2',
  green20: '#D1EFDA',
  green10: '#F5FBF7',

  blue90: '#00288E',
  blue80: '#0039CC',
  blue70: '#0045F6',
  blue60: '#3370FF',
  blue50: '#578FFF',
  blue40: '#80AEFF',
  blue30: '#BDD7FF',
  blue20: '#DBEBFF',
  blue10: '#F0F7FF',

  yellow90: '#9B6D00',
  yellow80: '#C58B00',
  yellow70: '#E29F00',
  yellow60: '#F3AB00',
  yellow50: '#F5B92B',
  yellow40: '#F7C652',
  yellow30: '#F9D682',
  yellow20: '#FBE6B2',
  yellow10: '#FEF7E5',

  blackAlpha5: 'rgba(0, 0, 0, 0.05)',
  blackAlpha20: 'rgba(0, 0, 0, 0.2)',
}

export const colors = {
  core,
  bg: {
    default: core.white,
    brandPrimary: core.uiPink,
    brandPrimaryHover: core.uiPinkLight,
    brandPrimaryActive: core.uiPinkDark,
    brandSecondary: core.brown,
    brandSecondaryHover: core.brownLight,
    brandSecondaryActive: core.brownDark,
    brandTertiary: core.offWhite,
    brandTertiaryHover: core.offWhiteLight,
    brandTertiaryActive: core.offWhiteDark,
    negative: core.red60,
    warning: core.yellow60,
    positive: core.green60,
    inset: core.gray10,
    backdrop: core.blackAlpha20,
  },
  text: {
    strong: core.black,
    default: core.brown,
    subtle: core.gray60,
    disabled: core.gray50,
    negative: core.red60,
    warning: core.yellow90,
    positive: core.green70,
    onBrandPrimary: core.brown,
    onBrandSecondary: core.offWhite,
    onBrandTertiary: core.brown,
  },
  icon: {
    default: core.brown,
    strong: core.black,
    subtle: core.gray60,
    disabled: core.gray50,
    negative: core.red60,
    warning: core.yellow70,
    positive: core.green70,
    onBrandPrimary: core.brown,
    onBrandSecondary: core.offWhite,
    onBrandTertiary: core.brown,
  },
  border: {
    default: core.gray30,
    defaultHover: core.gray40,
    defaultSelected: core.brown,
    strong: core.gray40,
    subtle: core.gray20,
    negative: core.red60,
    warning: core.yellow60,
    positive: core.green60,
  },
}
