import React, { useState } from 'react'

import type { LegitimateAny } from '../types'

import { useSafeLayoutEffect } from './use-safe-layout-effect'

/**
 * This is basically a copy of Radix UI's `useId` hook.
 */

// We `toString()` to prevent bundlers from trying to `import { useId } from 'react';`
const useReactId = (React as LegitimateAny)['useId'.toString()] || (() => undefined)
let count = 0

/**
 * Returns a stable ID that is unique to the component instance.
 */
export function useStableId(fixedId?: string | null): string {
  const [id, setId] = useState<string | undefined>(useReactId())
  // React versions older than 18 will have client-side ids only.
  useSafeLayoutEffect(() => {
    if (!fixedId) setId((reactId) => reactId ?? String(count++))
  }, [fixedId])
  return fixedId || (id ? `qds-${id}` : '')
}
