import { css, Global } from '@emotion/react'

import type { Theme } from '../theme'

const globalStyles = (theme: Theme) => css`
  body {
    font-family: ${theme.typography.body.md.fontFamily};
    line-height: 1.5;
    color: ${theme.colors.text.default};
  }

  /** Remove focus outline on mouse hover */
  *:focus:not(:focus-visible) {
    outline: none;
  }

  /** Add focus outline on keyboard focus */
  button:focus-visible,
  a:focus-visible,
  [role='button']:focus-visible,
  input[type='checkbox']:focus-visible,
  input[type='radio']:focus-visible {
    outline: none;
    box-shadow:
      0 0 0 2px white,
      0 0 0 4px ${theme.colors.border.defaultSelected};
  }
`

/**
 * Adds some global opinionated styles.
 */
export function GlobalStyles() {
  return <Global styles={globalStyles} />
}
