import { foundations } from './foundations'

const mediaQueries = {
  smUp: `@media(min-width: ${foundations.breakpoints.sm}px)`,
  mdUp: `@media(min-width: ${foundations.breakpoints.md}px)`,
  lgUp: `@media(min-width: ${foundations.breakpoints.lg}px)`,
  xlUp: `@media(min-width: ${foundations.breakpoints.xl}px)`,
  '2xlUp': `@media(min-width: ${foundations.breakpoints['2xl']}px)`,
} as const

export const theme = { ...foundations, mediaQueries }
export type Theme = typeof theme
