import styled from '@emotion/styled'
import isPropValid from '@emotion/is-prop-valid'
import { forwardRef } from 'react'

import type { VariantProps } from '../../styles'
import { createStyleVariants } from '../../styles'
import type * as Polymorphic from '../../utils/polymorphic'
import type { Theme } from '../../theme'
import type { ResponsiveProp } from '../../styles/responsive'
import { toMediaQueries } from '../../styles/responsive'

const getSizeStyles = createStyleVariants(({ typography }) => ({
  sm: {
    ...typography.label.sm,
  },
  md: {
    ...typography.label.md,
  },
}))

type LabelSize = VariantProps<typeof getSizeStyles>
type LabelColor = keyof Theme['colors']['text']

interface StyledLabelProps {
  size: ResponsiveProp<LabelSize>
  color: LabelColor
}
const StyledLabel = styled('label', { shouldForwardProp: isPropValid })<StyledLabelProps>(
  ({ theme, color, size }) => ({
    display: 'block',
    color: theme.colors.text[color],
    cursor: 'default',
    WebkitTouchCallout: 'none',
    WebkitTapHighlightColor: 'transparent',
    ...toMediaQueries(size, (currentValue) => getSizeStyles(theme)[currentValue]),
    '&[data-disabled]': {
      opacity: 0.4,
    },
  }),
)

interface LabelOptions {
  /**
   * Size of the label
   */
  size?: ResponsiveProp<LabelSize>
  /**
   * Sets the color of the label
   * @default 'default'
   */
  color?: LabelColor
}

type LabelComponent = Polymorphic.ForwardRefComponent<'label', LabelOptions>
export type LabelProps = Polymorphic.PropsOf<LabelComponent>

export const Label = forwardRef((props, forwardedRef) => {
  const { as, children, color = 'default', size = 'md', onMouseDown, ...restProps } = props
  return (
    <StyledLabel
      as={as}
      ref={forwardedRef}
      {...restProps}
      size={size}
      color={color}
      onMouseDown={(event) => {
        onMouseDown && onMouseDown(event)
        // prevent text selection when double clicking label
        if (!event.defaultPrevented && event.detail > 1) {
          event.preventDefault()
        }
      }}
    >
      {children}
    </StyledLabel>
  )
}) as LabelComponent
