import * as ToastPrimitive from '@radix-ui/react-toast'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'

import type { HTMLQdsProps } from '../../types'
import { AlertCircleIcon } from '../icon'

import { getVariantStyles } from './toast-styles'
import type { ToastVariant } from './toast-styles'

/**
 * We style a separate div as a child for Radix Toast.Root in order to meet the QDS accessibility requirements & animate it
 * Radix uses <ol> and <li> elements for Toast elements in combination with role="status", which is not allowed according to a11y standards
 * @see https://github.com/radix-ui/primitives/issues/1750
 */
const Wrapper = styled(motion.div)<{ variant: ToastVariant }>(({ theme, variant }) => ({
  borderRadius: theme.radii.md,
  paddingInline: theme.spacing['4x'],
  paddingBlock: theme.spacing['3x'],
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing['3x'],
  ...getVariantStyles(theme)[variant],
}))

const Title = styled(ToastPrimitive.Title)(({ theme }) => ({
  ...theme.typography.label.sm,
}))

const IconWrapper = styled.div({
  marginTop: 1,
  flexShrink: 0,
})

const ICON_MAP = {
  neutral: undefined,
  error: AlertCircleIcon,
}

interface ToastOptions {
  text: string
  variant?: ToastVariant
}

type OmittedProps = 'children'
interface ToastProps extends Omit<HTMLQdsProps<'div'>, OmittedProps>, ToastOptions {}

export function Toast(props: ToastProps & ToastPrimitive.ToastProps) {
  const { text, variant = 'neutral', ...rest } = props

  const Icon = ICON_MAP[variant]

  return (
    <ToastPrimitive.Root asChild forceMount {...rest}>
      <Wrapper
        variant={variant}
        layout
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0 }}
        transition={{ y: { type: 'spring', bounce: 0.1 } }}
      >
        {Icon && (
          <IconWrapper>
            <Icon size={16} color="onBrandSecondary" />
          </IconWrapper>
        )}
        <Title>{text}</Title>
      </Wrapper>
    </ToastPrimitive.Root>
  )
}
