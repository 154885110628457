import { flushSync } from 'react-dom'

import type { ToastVariant } from './toast-styles'

type Id = number | string
interface Toast {
  id: Id
  /**
   * The text for the toast
   */
  text: string
  /**
   * Sets the style variant of the toast, currently supports 'neutral' and 'negative'
   * @default 'neutral'
   */
  variant?: ToastVariant
}
interface ToastOptions {
  /**
   * Unique identifier for the toast (can be used for removing it prematurely). If a toast with this
   * identifier already exists it will be removed before the new toast is added.
   * @default a random unique id will be set and returned
   */
  id?: Id
}

type Subscriber = () => void
class ToastStore {
  toasts: Toast[]
  subscribers: Subscriber[]
  id: number

  constructor() {
    this.subscribers = []
    this.toasts = []
    this.id = 0
  }

  subscribe = (subscriber: Subscriber) => {
    this.subscribers.push(subscriber)

    return () => {
      const index = this.subscribers.indexOf(subscriber)
      this.subscribers.splice(index, 1)
    }
  }

  notify = () => {
    this.subscribers.forEach((subscriber) => subscriber())
  }

  add = (toast: Omit<Toast, 'id'> & ToastOptions) => {
    /*
     * Update to use the window.crypto.randomUUIC() method here after some time when we are sure that all the relevant browsers support it.
     */
    this.id = this.id + 1
    const id = toast.id ?? this.id
    if (toast.id) {
      // NOTE: if the toast.id is set we remove any toast with the same identifier
      this.toasts = this.toasts.filter((toast) => toast.id !== id)
      // we use flushSync to prevent batched state updates which would cause React to update the old
      // toast instead of removing it and adding a new one
      flushSync(() => {
        this.notify()
      })
    }
    this.toasts = [...this.toasts, { ...toast, id }]
    this.notify()

    return id
  }

  addNeutral = (text: string, options?: ToastOptions) => {
    return this.add({ text, variant: 'neutral', ...options })
  }

  addError = (text: string, options?: ToastOptions) => {
    return this.add({ text, variant: 'error', ...options })
  }

  remove = (id: Id) => {
    this.toasts = [...this.toasts.filter((toast) => toast.id !== id)]
    this.notify()
  }

  removeAll = () => {
    this.toasts = []
    this.notify()
  }

  getSnapshot = () => {
    return this.toasts
  }
}

export const toastStore = new ToastStore()

export const toast = Object.assign(toastStore.addNeutral, {
  error: toastStore.addError,
  remove: toastStore.remove,
  removeAll: toastStore.removeAll,
})
