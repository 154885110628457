import { createContext, useContext } from 'react'

import type { LanguageCode } from './locales'

interface LocaleContextValue {
  currentLanguage: LanguageCode
}

const LocaleContext = createContext<LocaleContextValue | undefined>(undefined)

interface LocaleProviderProps {
  language: LanguageCode
  children: React.ReactNode
}
export function LocaleProvider({ language, children }: LocaleProviderProps) {
  return (
    <LocaleContext.Provider value={{ currentLanguage: language }}>
      {children}
    </LocaleContext.Provider>
  )
}

export function useLocale() {
  const context = useContext(LocaleContext)
  if (!context) {
    throw new Error('useLocale must be used within a LocaleProvider')
  }

  return context
}
