import { pxToRem } from '../../styles'

const fontStacks = {
  display: [
    '"Qasa Diatype Rounded Semi-Mono"',
    'Helvetica',
    '-apple-system',
    'BlinkMacSystemFont',
    'Roboto',
    '"Helvetica Neue"',
    'sans-serif',
  ].join(','),
  sans: [
    '"Qasa Diatype Rounded"',
    'Helvetica',
    '-apple-system',
    'BlinkMacSystemFont',
    'Roboto',
    '"Helvetica Neue"',
    'sans-serif',
  ].join(','),
}

export const typography = {
  display: {
    '3xl': {
      fontFamily: fontStacks.display,
      fontWeight: '700',
      fontSize: pxToRem(80),
      lineHeight: pxToRem(80),
      letterSpacing: '-0.06em',
      fontFeatureSettings: "'ss05' on",
    },
    '2xl': {
      fontFamily: fontStacks.display,
      fontWeight: '700',
      fontSize: pxToRem(72),
      lineHeight: pxToRem(72),
      letterSpacing: '-0.06em',
      fontFeatureSettings: "'ss05' on",
    },
    xl: {
      fontFamily: fontStacks.display,
      fontWeight: '700',
      fontSize: pxToRem(64),
      lineHeight: pxToRem(64),
      letterSpacing: '-0.05em',
      fontFeatureSettings: "'ss05' on",
    },
    lg: {
      fontFamily: fontStacks.display,
      fontWeight: '700',
      fontSize: pxToRem(56),
      lineHeight: pxToRem(56),
      letterSpacing: '-0.05em',
      fontFeatureSettings: "'ss05' on",
    },
    md: {
      fontFamily: fontStacks.display,
      fontWeight: '700',
      fontSize: pxToRem(48),
      lineHeight: pxToRem(48),
      letterSpacing: '-0.04em',
      fontFeatureSettings: "'ss05' on",
    },
    sm: {
      fontFamily: fontStacks.display,
      fontWeight: '700',
      fontSize: pxToRem(40),
      lineHeight: pxToRem(44),
      letterSpacing: '-0.04em',
      fontFeatureSettings: "'ss05' on",
    },
    xs: {
      fontFamily: fontStacks.display,
      fontWeight: '700',
      fontSize: pxToRem(32),
      lineHeight: pxToRem(36),
      letterSpacing: '-0.03em',
      fontFeatureSettings: "'ss05' on",
    },
  },
  title: {
    lg: {
      fontFamily: fontStacks.sans,
      fontWeight: '700',
      fontSize: pxToRem(32),
      lineHeight: pxToRem(36),
      letterSpacing: '-0.02em',
    },
    md: {
      fontFamily: fontStacks.sans,
      fontWeight: '700',
      fontSize: pxToRem(24),
      lineHeight: pxToRem(28),
      letterSpacing: '-0.02em',
    },
    sm: {
      fontFamily: fontStacks.sans,
      fontWeight: '700',
      fontSize: pxToRem(20),
      lineHeight: pxToRem(24),
      letterSpacing: '-0.02em',
    },
    xs: {
      fontFamily: fontStacks.sans,
      fontWeight: '700',
      fontSize: pxToRem(18),
      lineHeight: pxToRem(22),
      letterSpacing: '-0.015em',
    },
    '2xs': {
      fontFamily: fontStacks.sans,
      fontWeight: '700',
      fontSize: pxToRem(16),
      lineHeight: pxToRem(20),
      letterSpacing: '-0.01em',
    },
    '3xs': {
      fontFamily: fontStacks.sans,
      fontWeight: '700',
      fontSize: pxToRem(14),
      lineHeight: pxToRem(18),
      letterSpacing: '-0.005em',
    },
  },
  body: {
    xl: {
      fontFamily: fontStacks.sans,
      fontWeight: '400',
      fontSize: pxToRem(20),
      lineHeight: pxToRem(28),
      letterSpacing: '-0.02em',
    },
    lg: {
      fontFamily: fontStacks.sans,
      fontWeight: '400',
      fontSize: pxToRem(18),
      lineHeight: pxToRem(26),
      letterSpacing: '-0.02em',
    },
    md: {
      fontFamily: fontStacks.sans,
      fontWeight: '400',
      fontSize: pxToRem(16),
      lineHeight: pxToRem(24),
      letterSpacing: '-0.01em',
    },
    sm: {
      fontFamily: fontStacks.sans,
      fontWeight: '400',
      fontSize: pxToRem(14),
      lineHeight: pxToRem(20),
      letterSpacing: '-0.01em',
    },
    xs: {
      fontFamily: fontStacks.sans,
      fontWeight: '400',
      fontSize: pxToRem(12),
      lineHeight: pxToRem(16),
      letterSpacing: '0',
    },
  },
  label: {
    md: {
      fontFamily: fontStacks.sans,
      fontWeight: '500',
      fontSize: pxToRem(16),
      lineHeight: pxToRem(20),
      letterSpacing: '-0.02em',
    },
    sm: {
      fontFamily: fontStacks.sans,
      fontWeight: '500',
      fontSize: pxToRem(14),
      lineHeight: pxToRem(18),
      letterSpacing: '-0.01em',
    },
  },
  button: {
    md: {
      fontFamily: fontStacks.sans,
      fontWeight: '700',
      fontSize: pxToRem(16),
      lineHeight: pxToRem(18),
      letterSpacing: '-0.01em',
    },
    sm: {
      fontFamily: fontStacks.sans,
      fontWeight: '700',
      fontSize: pxToRem(14),
      lineHeight: pxToRem(16),
      letterSpacing: '-0.01em',
    },
  },
  caption: {
    md: {
      fontFamily: fontStacks.sans,
      fontWeight: '700',
      fontSize: pxToRem(12),
      lineHeight: pxToRem(12),
      letterSpacing: '0',
    },
    sm: {
      fontFamily: fontStacks.sans,
      fontWeight: '700',
      fontSize: pxToRem(10),
      lineHeight: pxToRem(10),
      letterSpacing: '0',
    },
  },
}
