import { forwardRef } from 'react'

import type { HTMLQdsProps } from '../../types'

interface SelectOptionOptions {
  isDisabled?: boolean
}

type OmittedProps = 'disabled' | 'label'
export interface SelectOptionProps
  extends Omit<HTMLQdsProps<'option'>, OmittedProps>,
    SelectOptionOptions {}

export const SelectOption = forwardRef<HTMLOptionElement, SelectOptionProps>(
  (props, forwardedRef) => {
    const { children, isDisabled, ...restProps } = props
    return (
      <option ref={forwardedRef} disabled={isDisabled} {...restProps}>
        {children}
      </option>
    )
  },
)
