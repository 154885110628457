import type { ElementType } from 'react'
import { forwardRef } from 'react'
import styled from '@emotion/styled'
import isPropValid from '@emotion/is-prop-valid'

import type { VariantProps } from '../../styles'
import type { IconProps } from '../icon'
import type * as Polymorphic from '../../utils/polymorphic'
import { toMediaQueries, type ResponsiveProp } from '../../styles/responsive'

import { getSizeStyles, getVariantStyles } from './icon-button-styles'

const roundToTwoDecimals = (num: number) => Math.round(num * 100) / 100

const ICON_SIZE_MAP = {
  xs: 16,
  sm: 20,
  md: 20,
} as const

interface StyledButtonProps {
  size: ResponsiveProp<IconButtonSize>
  variant: IconButtonVariant
}
const StyledButton = styled('button', { shouldForwardProp: isPropValid })<StyledButtonProps>(
  ({ theme, size: iconSize, variant }) => ({
    borderRadius: theme.radii.full,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    flexShrink: 0,
    WebkitTouchCallout: 'none',
    WebkitTapHighlightColor: 'transparent',
    userSelect: 'none',
    transitionProperty: 'box-shadow, transform, opacity, background-color, color',
    transitionDuration: '150ms',
    transitionTimingFunction: 'ease',

    '&[disabled]': {
      opacity: 0.4,
    },
    '&:not([disabled]):active': {
      transform: 'scale(0.97)',
    },
    ...toMediaQueries(iconSize, (currentValue) => {
      const iconSize = ICON_SIZE_MAP[currentValue]
      return {
        ...getSizeStyles(theme)[currentValue],
        '> svg': {
          width: iconSize,
          height: iconSize,
          // Always set a minimum stroke width of 2px.
          // If the size is less than 24px, scale the stroke width up
          // to compensate for the scaling down of the icon.
          ...(iconSize < 24 && {
            strokeWidth: roundToTwoDecimals((24 / iconSize) * 2),
          }),
        },
      }
    }),
    ...getVariantStyles(theme)[variant],
  }),
)

type IconButtonSize = VariantProps<typeof getSizeStyles>
type IconButtonVariant = VariantProps<typeof getVariantStyles>
interface IconButtonOptions {
  icon: ElementType<IconProps>
  /**
   * A visually hidden label read by screen readers.
   *
   */
  label: string
  /**
   * Defines the size of the button
   */
  size?: ResponsiveProp<IconButtonSize>
  /**
   * Sets the style variant of the button
   */
  variant?: IconButtonVariant
  /**
   * If `true` the button will be disabled
   */
  isDisabled?: boolean
}

type IconButtonComponent = Polymorphic.ForwardRefComponent<'button', IconButtonOptions>
export type IconButtonProps = Polymorphic.PropsOf<IconButtonComponent>

export const IconButton = forwardRef((props, forwardedRef) => {
  const {
    as,
    icon: Icon,
    label,
    'aria-label': ariaLabel = label,
    variant = 'ghost',
    size = 'md',
    type = 'button',
    isDisabled = false,
    ...restProps
  } = props
  return (
    <StyledButton
      as={as}
      ref={forwardedRef}
      aria-label={ariaLabel}
      variant={variant}
      size={size}
      disabled={isDisabled}
      type={type}
      {...restProps}
    >
      <Icon aria-hidden="true" color="currentColor" />
    </StyledButton>
  )
}) as IconButtonComponent
