import { useEffect, useState } from 'react'

import { theme } from '../theme'
import type { Theme } from '../theme'

type Breakpoints = Theme['breakpoints']

const breakpointsArray = Object.entries(theme.breakpoints).map(([name, value]) => ({
  name: name as keyof Breakpoints,
  breakpoint: value,
}))

const MEDIA_QUERIES = breakpointsArray.map(({ name, breakpoint }, index) => {
  const nextValue = breakpointsArray?.[index + 1]?.breakpoint
  const media = nextValue
    ? `(min-width: ${breakpoint}px) and (max-width: ${nextValue - 1}px)`
    : `(min-width: ${breakpoint}px)`

  return {
    name,
    media,
  }
})

const getMatchingBreakpoint = () => {
  const matchingBreakpoint = MEDIA_QUERIES.find(({ media }) => {
    return window.matchMedia(media).matches
  })
  // Since the breakpoints cover all screen sizes we should always get a match,
  // but to be safe we return 'base' as a fallback.
  return matchingBreakpoint?.name || 'base'
}

export interface UseBreakpointOptions {
  /**
   * If `true` the initial value will be `base` instead of the current breakpoint.
   * This is to support hydration when using server side rendering.
   *
   * @default false
   */
  ssr?: boolean
}

/**
 * Hook for getting the current breakpoint.
 */
export function useBreakpoint(params?: UseBreakpointOptions) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { ssr = false } = params || {}
  const initialBreakpoint = ssr ? 'base' : getMatchingBreakpoint
  const [currentBreakpoint, setCurrentBreakpoint] = useState<keyof Breakpoints>(initialBreakpoint)

  useEffect(() => {
    const matchMediaArray = MEDIA_QUERIES.map(({ media }) => window.matchMedia(media))

    const handleChange = () => {
      // Instead of checking the value from the matchMedia listeners event we just use it
      // as trigger to know when the current breakpoint should be updated.
      // This is because each separate query has its own event listener which
      // can lead to incorrect intermediate states where no breakpoint is matching.
      setCurrentBreakpoint(getMatchingBreakpoint())
    }

    // Update the current breakpoint on the first render.
    // After that the event listeners will take care of updating the state.
    handleChange()

    matchMediaArray.forEach((mediaQuery) => {
      if (typeof mediaQuery.addListener === 'function') {
        // Safari < 14 fallback
        mediaQuery.addListener(handleChange)
      } else {
        mediaQuery.addEventListener('change', handleChange)
      }
    })

    return () => {
      matchMediaArray.forEach((mediaQuery) => {
        if (typeof mediaQuery.addListener === 'function') {
          mediaQuery.removeListener(handleChange)
        } else {
          mediaQuery.removeEventListener('change', handleChange)
        }
      })
    }
  }, [])

  return { currentBreakpoint }
}
