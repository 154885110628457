import { useLocale } from './locale-context'
import type { Resource } from './locales'
import { locales } from './locales'

type I18nKey = keyof Resource

export function useTranslation() {
  const { currentLanguage } = useLocale()

  const t = (key: I18nKey) => locales[currentLanguage][key]

  return { t }
}
