import * as DropdownPrimitive from '@radix-ui/react-dropdown-menu'
import type { ReactNode } from 'react'

import { DropdownMenuContent, type DropdownMenuContentProps } from './dropdown-menu-content'
import { DropdownMenuDivider, type DropdownMenuDividerProps } from './dropdown-menu-divider'
import { DropdownMenuItem, type DropdownMenuItemProps } from './dropdown-menu-item'
import type { DropdownMenuTriggerProps } from './dropdown-menu-trigger'
import { DropdownMenuTrigger } from './dropdown-menu-trigger'

interface DropdownMenuRootProps {
  children: ReactNode
  /**
   * If `true` the dropdown menu will be open
   */
  isOpen?: boolean
  /**
   * The open state of the submenu when it is initially rendered.
   * Use when you do not need to control its open state.
   */
  defaultOpen?: boolean
  /**
   * Callback invoked open state changes
   */
  onOpenChange?: (isOpen: boolean) => void
}

function DropdownMenuRoot(props: DropdownMenuRootProps) {
  //eslint-disable-next-line @typescript-eslint/naming-convention
  const { children, isOpen, onOpenChange, defaultOpen = false } = props
  return (
    <DropdownPrimitive.Root
      defaultOpen={defaultOpen}
      open={isOpen}
      onOpenChange={onOpenChange}
      modal
    >
      {children}
    </DropdownPrimitive.Root>
  )
}

export const DropdownMenu = Object.assign(DropdownMenuRoot, {
  Trigger: DropdownMenuTrigger,
  Content: DropdownMenuContent,
  Item: DropdownMenuItem,
  Divider: DropdownMenuDivider,
})

export type {
  DropdownMenuRootProps,
  DropdownMenuTriggerProps,
  DropdownMenuContentProps,
  DropdownMenuItemProps,
  DropdownMenuDividerProps,
}
