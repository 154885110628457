import { createContext, useContext } from 'react'

interface RadioGroupContextValue {
  labelRefCallback: (node: HTMLElement | null) => void
  isDisabled: boolean | undefined
  errorMessageId?: string
}
const RadioGroupContext = createContext<RadioGroupContextValue | null>(null)

interface RadioGroupProviderProps {
  value: RadioGroupContextValue
  children: React.ReactNode
}
export function RadioGroupProvider({ value, children }: RadioGroupProviderProps) {
  return <RadioGroupContext.Provider value={value}>{children}</RadioGroupContext.Provider>
}

interface UseRadioGroupContextProps {
  consumerName: string
}

export const useRadioGroupContext = ({ consumerName }: UseRadioGroupContextProps) => {
  const context = useContext(RadioGroupContext)
  if (!context) {
    throw new Error(`\`${consumerName}\` must be used within \`RadioGroup\``)
  }
  return context
}
