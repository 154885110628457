import * as DropdownTriggerPrimitive from '@radix-ui/react-dropdown-menu'
import { forwardRef } from 'react'

import type * as Polymorphic from '../../utils/polymorphic'

type DropdownTriggerComponent = Polymorphic.ForwardRefComponent<'button'>

export type DropdownMenuTriggerProps = Polymorphic.PropsOf<DropdownTriggerComponent>

export const DropdownMenuTrigger = forwardRef((props, forwardedRef) => {
  const { children, as, ...restProps } = props
  const Component = as || 'button'

  return (
    <DropdownTriggerPrimitive.Trigger ref={forwardedRef} asChild>
      <Component {...restProps}>{children}</Component>
    </DropdownTriggerPrimitive.Trigger>
  )
}) as DropdownTriggerComponent
