import styled from '@emotion/styled'
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import { forwardRef, useState } from 'react'

import { useStableId } from '../../hooks'
import { pxToRem } from '../../styles'
import { dataAttr } from '../../utils/html-attributes'
import { HelperText } from '../_internal'

import { RadioIndicator } from './radio-indicator'
import { useRadioGroupContext } from './radio-group-context'

const RadioItem = styled(RadioGroupPrimitive.Item)(({ theme }) => ({
  width: '100%',
  textAlign: 'left',
  background: theme.colors.bg.default,
  paddingLeft: theme.spacing['5x'],
  paddingRight: theme.spacing['5x'],
  // Odd padding value, but without it the text is not vertically centered.
  // Logic is (height - line height - border top - border bottom) / 2
  // So (56 - 20 - 1 - 1) / 2 = 17
  paddingTop: pxToRem(17),
  paddingBottom: pxToRem(17),
  minHeight: pxToRem(56),
  justifyContent: 'center',
  border: '1px solid',
  borderColor: theme.colors.border.default,
  borderRadius: theme.radii.md,
  display: 'grid',
  gridTemplateColumns: `1fr min-content`,
  alignItems: 'start',
  // Needed to align the content to the top when the cards are
  // places in a grid with varying intrinsic heights
  alignContent: 'start',
  columnGap: theme.spacing['3x'],
  rowGap: theme.spacing['1x'],
  boxShadow: 'none',
  transitionProperty: 'background, border-color, box-shadow, transform, outline',
  transitionDuration: '120ms',
  transitionTimingFunction: 'ease-out',
  '&[data-state="checked"]': {
    borderColor: theme.colors.border.defaultSelected,
    boxShadow: `inset 0 0 0 1px ${theme.colors.border.defaultSelected}`,
    background: theme.colors.bg.inset,
    ':active': {
      transform: 'scale(0.995)',
    },
  },
  '&[data-state="unchecked"]': {
    '@media(hover: hover)': {
      ':hover': {
        borderColor: theme.colors.border.defaultHover,
        background: theme.colors.bg.inset,
      },
    },
    ':active': {
      transform: 'scale(0.98)',
    },
  },
  outlineOffset: 0,
  outline: '0 solid transparent',
  ':focus-visible': {
    outlineColor: theme.colors.border.defaultSelected,
    outlineWidth: 2,
    outlineStyle: 'solid',
    outlineOffset: 3,
  },
  '&[data-disabled], &:disabled, &[disabled]': {
    opacity: 0.4,
    '&[data-state="unchecked"]': {
      ':hover': {
        background: theme.colors.bg.default,
        borderColor: theme.colors.border.default,
      },
    },
    ':active': {
      transform: 'none',
    },
  },

  WebkitTouchCallout: 'none',
  WebkitTapHighlightColor: 'transparent',
}))

interface RadioCardOptions {
  /**
   * The label for the radio card
   */
  label: string
  /**
   * Text that provides additional guidance to the user
   */
  helperText?: string
  /**
   * If `true` the user must check the radio item before the owning form can be submitted.
   * @default false
   */
  isRequired?: boolean
  /**
   * If `true` it prevents the user from interacting with the radio item.
   * @default false
   */
  isDisabled?: boolean
}

export interface RadioCardProps
  extends Omit<RadioGroupPrimitive.RadioGroupItemProps, 'asChild' | keyof RadioCardOptions>,
    RadioCardOptions {}

const Label = styled.span(({ theme }) => ({
  ...theme.typography.label.md,
}))

export const RadioCard = forwardRef<HTMLButtonElement, RadioCardProps>((props, forwardedRef) => {
  const {
    label,
    helperText,
    disabled: hasHtmlDisabledAttr,
    isDisabled: isDisabledProp,
    required: hasHtmlRequiredAttr,
    isRequired: isRequiredProp,
    id: idProp,
    'aria-describedby': ariaDescribedByProp,
    onMouseEnter,
    onMouseLeave,
    ...rest
  } = props
  const [isHovered, setIsHovered] = useState(false)

  const { errorMessageId } = useRadioGroupContext({ consumerName: 'RadioCard' })
  const id = useStableId(idProp)
  const labelId = `${id}-label`
  const hasHelperText = Boolean(helperText)
  const helperTextId = hasHelperText ? `${id}-helper-text` : undefined

  const ariaDescribedBy =
    [errorMessageId, helperTextId, ariaDescribedByProp].filter(Boolean).join(' ') || undefined

  const helperTextElement = helperText ? (
    <HelperText id={helperTextId}>{helperText}</HelperText>
  ) : null

  const isDisabled = isDisabledProp ?? hasHtmlDisabledAttr
  const isRequired = isRequiredProp ?? hasHtmlRequiredAttr

  return (
    <RadioItem
      ref={forwardedRef}
      disabled={isDisabled}
      // For some reason if we pass e.g. `required={undefined}` it will override
      // the `required` attribute from the `RadioGroup`. Seems to be something with
      // how Radix handles the state internally.
      {...(isRequired && { required: true })}
      aria-labelledby={labelId}
      aria-describedby={ariaDescribedBy}
      onMouseEnter={(ev) => {
        setIsHovered(true)
        onMouseEnter?.(ev)
      }}
      onMouseLeave={(ev) => {
        setIsHovered(false)
        onMouseLeave?.(ev)
      }}
      {...rest}
    >
      <Label id={labelId}>{label}</Label>
      <RadioIndicator data-hover={dataAttr(isHovered)} />
      {helperTextElement}
    </RadioItem>
  )
})
