import { forwardRef } from 'react'
import styled from '@emotion/styled'
import isPropValid from '@emotion/is-prop-valid'

import type * as Polymorphic from '../../utils/polymorphic'

const StyledLink = styled('a', { shouldForwardProp: isPropValid })(({ theme }) => ({
  WebkitTouchCallout: 'none',
  WebkitTapHighlightColor: 'transparent',
  ...theme.typography.body.md,
  WebkitAppearance: 'none',
  transitionProperty: 'box-shadow, transform, opacity, background-color, color',
  transitionDuration: '150ms',
  transitionTimingFunction: 'ease',
  flexShrink: 0,
  fontSize: 'inherit',
  color: 'inherit',
  textDecoration: 'underline',
}))

interface LinkOptions {
  /**
   * The URL to link to.
   */
  href?: string
  /**
   * If `true`, the link will open in new tab
   */
  isExternal?: boolean
}

type LinkComponent = Polymorphic.ForwardRefComponent<'a', LinkOptions>
export type LinkProps = Polymorphic.PropsOf<LinkComponent>

export const Link = forwardRef((props, forwardedRef) => {
  const { children, href, isExternal = false, ...restProps } = props
  const externalProps = isExternal ? { target: '_blank', rel: 'noopener noreferrer' } : {}
  return (
    <StyledLink ref={forwardedRef} href={href} {...externalProps} {...restProps}>
      {children}
    </StyledLink>
  )
}) as LinkComponent
