import * as ToastPrimitive from '@radix-ui/react-toast'
import { useSyncExternalStore } from 'react'
import type { ReactNode } from 'react'
import styled from '@emotion/styled'
import { AnimatePresence } from 'framer-motion'

import { toastStore } from './toast-store'
import { Toast } from './toast'

/**
 * We style a separate div as a child for Radix Viewport in order to meet the QDS accessibility requirements
 * Radix uses <ol> and <li> elements for Toast elements in combination with role="status", which is not allowed according to a11y standards
 * @see https://github.com/radix-ui/primitives/issues/1750
 */
const Viewport = styled.div(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing['12x'],
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: theme.zIndices.toast,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing['2x'],
  width: `calc(100vw - ${theme.spacing['8x']})`,
  [theme.mediaQueries.smUp]: {
    width: theme.sizes[320],
  },
}))

export function ToastProvider({ children }: { children: ReactNode }) {
  const toasts = useSyncExternalStore(
    toastStore.subscribe,
    toastStore.getSnapshot,
    toastStore.getSnapshot,
  )

  return (
    <ToastPrimitive.Provider>
      {children}
      <AnimatePresence>
        {toasts.map(({ id, text, variant }) => (
          <Toast
            key={id}
            text={text}
            variant={variant}
            onOpenChange={(isOpen) => {
              if (!isOpen) {
                toastStore.remove(id)
              }
            }}
          />
        ))}
      </AnimatePresence>
      <ToastPrimitive.Viewport asChild>
        <Viewport />
      </ToastPrimitive.Viewport>
    </ToastPrimitive.Provider>
  )
}
